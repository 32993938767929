import React, { useEffect, useState } from "react"
import { CreatorPayload } from "../../store/types"
import { API_URL, MEDIA_URL, DEFAULT_PRODUCT, DEFAULT_PRODUCT_CATEGORY, ROOT_PRODUCT_CATEGORY, META_ORDER } from "../../config"
import CreatorApp from "./App"
import { useAppState } from "../../store/hooks/app"
import { get } from "../../store/actions/fetch"
import { useParams } from "react-router-dom"
import { useCartState } from "../../store/hooks/cart"
import { loadCart } from "../../store/actions/cart"
import { useDispatch } from "react-redux"
import Loader from "../Loader"

interface CreatorProps {
    idCategory: number
    idProduct?: number
}

const Creator = ({ idCategory, idProduct }: CreatorProps) => {
    const version = `0.0.4`
    const app = useAppState()
    const { cart } = useCartState();
    const { motive, ipa } = useParams();
    const dispatch = useDispatch();


    const [ options, setOptions ] = useState<CreatorPayload | null>(null);
    const [ loaded, setLoaded ] = useState<number>(0)
    
    const addJs = (url: string, onload: () => void = () => {}) => {
        const h = document.getElementsByTagName('head')[0];
        const js  = document.createElement('script');
        js.classList.add('creator-js');
        js.src = url.substr(0,4) === 'http' ? url : `${process.env.PUBLIC_URL}/creator/${url}.js`;
        js.onload = onload;
        h.appendChild(js);
        return js;
    }

    useEffect(() => {
        (async () => {
            if (loaded === 0) {
                setLoaded(1)
                const payload:CreatorPayload = await get(`creator/${app.config?.lang}/${idCategory}`);
                setOptions(payload)
                setLoaded(2)
            }
        })()
    }, [ loaded, app, idCategory ])


    useEffect(() => {
        //@ts-ignore
        window.refreshCart = () => {
            (async () => {
                if (app.config?.lang) 
                    dispatch(await loadCart(app.config.lang))
            })()
        }
        
        return () => {
            //@ts-ignore
            delete window.refreshCart
        }
    }, [ cart, app, dispatch ])

    useEffect(() => {
        
        if (loaded === 2) {
            if (!document.getElementsByClassName('creator-js').length) {

                const cjs = document.getElementsByTagName('head')[0];
                [ 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
                'css/jquery-ui.min', `dist/creator.${version}`

                ].forEach(url => {
                    const css  = document.createElement('link');
                    css.rel = "stylesheet"
                    css.href = url.substr(0,4) === 'http' ? url : `${process.env.PUBLIC_URL}/creator/${url}.css`;
                    cjs.prepend(css);
                })

                addJs('https://code.jquery.com/jquery-3.4.1.min.js').onload = () => {
                    addJs('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js').onload = () => {
                        [ 'js/vendor/jquery-ui.min', `dist/creator.${version}` ].forEach(l => {
                            addJs(l, () => {
                                setLoaded(3)
                            })
                        })
                    }
                }
            }
            else
                setLoaded(3)
        }

        return () => {
            Array.from(document.getElementsByClassName('creator-js')).forEach(el => {
                el.parentNode?.removeChild(el)
            })
        }
    }, [ loaded, version ])
    

    if (!app.loaded || !app.config || loaded < 3)
        return <Loader />;
    
    const route = app.config?.routes.meta.find(r => r.id === META_ORDER)

    const els = []
    for (let t in options?.translations)
        els.push(<span key={`transl-${t}`} data-code={t}>{options?.translations[t]}</span>)

    return (
        <div id="creator">
            <div id="creator-variables">
                <input type="hidden" id="PARAM_DEF_ISO_CODE" value={app.config.lang}/>
                <input type="hidden" id="PARAM_REST_URL" value={API_URL.replace(/v3\/$/, '')}/>
                <input type="hidden" id="PARAM_DEF_PRODUCT" value={idProduct || options?.id_product || DEFAULT_PRODUCT} />
                <input type="hidden" id="PARAM_DEFAULT_PRINTING" value={motive}/>
                <input type="hidden" id="PARAM_DEF_QUANTITY" value="1"/>
                <input type="hidden" id="PARAM_DEF_CATEGORY" value={idCategory || DEFAULT_PRODUCT_CATEGORY } />
                <input type="hidden" id="PARAM_DEF_PARENT_CATEGORY" value={options?.id_parent || ROOT_PRODUCT_CATEGORY } />
                <input type="hidden" id="PARAM_ORDER_LINK" value={`/${app.config.lang}/${route?.slug}`}/>
                <input type="hidden" id="PARAM_DEF_TEXT" value=""/>
                <input type="hidden" id="PARAM_ID_CART" value={cart.id} />
                <input type="hidden" id="PARAM_IPA" value={ipa || 'default'} />
                <input type="hidden" id="PARAM_SECURE_KEY" value={cart.key} />
                <input type="hidden" id="PARAM_THUMBS_URL" value={MEDIA_URL.concat('thumbs/')}/>
                <input type="hidden" id="PARAM_DECIMALS" value={app.config.currency.decimals} />
            </div>
            <div id="creator-translations" style={{ display: "none" }}>
                <span key='transl-currency' data-code="currency">{app.config.currency.sign}</span>
                {els}
            </div>

            <CreatorApp lang={app.config.lang} idCategory={idCategory} baseUrl={app.config.url} restUrl={API_URL.replace(/v3\/$/, '')} />
        </div>
    )
}


export default Creator;

