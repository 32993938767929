
import { useSelector, useDispatch } from "react-redux";
import { State } from "../types"
import { useEffect } from "react";
import { loadConfig } from "../actions/app"
import { useLocation } from "react-router-dom"

export const useAppState = () => {
    const dispatch = useDispatch();
    const payload = useSelector((state: State) => state.app)

    useEffect(() => {
        (async () => {
            if (!payload.config) {
                dispatch(await loadConfig())
            }
        })()

    }, [ dispatch, payload ])

    return payload;
}

export const useTranslations = (keys: string[]) => {
    const translations = useSelector((state: State) => state.app.config?.translations)
    let result:{[t: string]: string} = {}
    
    if (translations) 
        keys.forEach(k => {
            result[k] = translations.hasOwnProperty(k) ? translations[k] : k
        })

    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
} 
    