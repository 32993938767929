import React from "react"
import "./order.scss"
import { useCartState } from "../../store/hooks/cart"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { Link } from "react-router-dom"
import { DEFAULT_PRODUCT_CATEGORY } from "../../config"
import Button from "../Account/Button"


interface ButtonsProps {
    handleForward: () => Promise<any>
    disabled?: boolean
    processing?: boolean
    nextLabel?: string
}

const Buttons = ({ handleForward, disabled = false, processing = false, nextLabel }: ButtonsProps) => {
    const { config } = useAppState()
    const t = useTranslations([
        'Pokračovať',
        'Späť'
    ])

    const { cart, goBackwards } = useCartState();
    const route = config?.routes.categories.find(r => r.id === DEFAULT_PRODUCT_CATEGORY);

    return (
        <nav>
            { cart.step === 1 ? 
                <Link to={`/${route?.slug}`}>
                    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#000" d="M 7.265625 9.003906 L 14 2.269531 C 14.183594 2.085938 14.289062 1.835938 14.289062 1.574219 C 14.289062 1.308594 14.183594 1.0625 14 0.878906 L 13.410156 0.289062 C 13.226562 0.101562 12.976562 0 12.714844 0 C 12.449219 0 12.203125 0.101562 12.015625 0.289062 L 4 8.304688 C 3.8125 8.492188 3.710938 8.738281 3.710938 9.003906 C 3.710938 9.269531 3.8125 9.515625 4 9.703125 L 12.011719 17.710938 C 12.195312 17.898438 12.441406 18 12.707031 18 C 12.96875 18 13.21875 17.898438 13.402344 17.710938 L 13.992188 17.121094 C 14.375 16.738281 14.375 16.113281 13.992188 15.730469 Z M 7.265625 9.003906 "/></svg>
                    {t['Späť']}
                </Link> :
                <Button processing={processing} onClick={async () => { goBackwards() }}>
                    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#ffffff" d="M 7.265625 9.003906 L 14 2.269531 C 14.183594 2.085938 14.289062 1.835938 14.289062 1.574219 C 14.289062 1.308594 14.183594 1.0625 14 0.878906 L 13.410156 0.289062 C 13.226562 0.101562 12.976562 0 12.714844 0 C 12.449219 0 12.203125 0.101562 12.015625 0.289062 L 4 8.304688 C 3.8125 8.492188 3.710938 8.738281 3.710938 9.003906 C 3.710938 9.269531 3.8125 9.515625 4 9.703125 L 12.011719 17.710938 C 12.195312 17.898438 12.441406 18 12.707031 18 C 12.96875 18 13.21875 17.898438 13.402344 17.710938 L 13.992188 17.121094 C 14.375 16.738281 14.375 16.113281 13.992188 15.730469 Z M 7.265625 9.003906 "/></svg>
                    {t['Späť']}
                </Button>
            }
                <Button processing={processing} onClick={handleForward} disabled={disabled}>
                    {nextLabel || t['Pokračovať']}
                    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#ffffff" d="M 14 8.296875 L 5.988281 0.289062 C 5.804688 0.101562 5.558594 0 5.292969 0 C 5.03125 0 4.78125 0.101562 4.597656 0.289062 L 4.007812 0.878906 C 3.625 1.261719 3.625 1.886719 4.007812 2.269531 L 10.734375 8.996094 L 4 15.730469 C 3.816406 15.914062 3.710938 16.164062 3.710938 16.425781 C 3.710938 16.691406 3.816406 16.9375 4 17.121094 L 4.589844 17.710938 C 4.777344 17.898438 5.023438 18 5.285156 18 C 5.550781 18 5.796875 17.898438 5.984375 17.710938 L 14 9.695312 C 14.1875 9.507812 14.289062 9.261719 14.289062 8.996094 C 14.289062 8.730469 14.1875 8.484375 14 8.296875 Z M 14 8.296875 " /></svg>
                </Button> 
        </nav>
    )
}


export default Buttons;

