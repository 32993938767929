import { combineReducers } from "redux"
import { appReducer } from "./app"
import { cartReducer } from "./cart"
import { orderReducer } from "./order"

export default combineReducers({
    app: appReducer,
    cart: cartReducer,
    order: orderReducer
})
