import React from "react"
import "./index.scss"
import Slider from "./Slider"
import Designs from "./Designs"
import Categories from "./Categories"
import Promo from "./Promo"
import Instagram from "./Instagram"

const Index = () => {
    return (
        <>
            <Slider />
            <Designs />
            <Categories />
            <Instagram />
            <Promo />
        </>
    )
}


export default Index;

