import React from "react"

interface CanvasProps {
    baseUrl: string
}

export default ({ baseUrl }: CanvasProps) => {
    
    return (
        <div className="main-canvas-container">

    <div className="block-container">
        <div className="undo-controls">
             <span className="control-help btn btn-default iq-trans" data-toggle="tooltip" data-code="tooltip.showHelp" data-placement="bottom" data-attr="title" title="Show help">
                <span className="fa fa-question help-bulb"></span>
            </span>

            <span className="control-undo btn btn-default disabled iq-trans" data-toggle="tooltip" data-code="tooltip.undo"
                  data-placement="bottom" data-attr="title" title="Undo">
                <span className="fa fa-reply iq-load"></span>
            </span>
            <span className="control-redo btn btn-default disabled iq-trans" data-toggle="tooltip" data-code="tooltip.redo"
                  data-placement="bottom" data-attr="title" title="Redo">
                <span className="fa fa-share iq-load"></span>
            </span>
        </div>

        <div className="external-controls-container">
            <div className="control-wrap">
                <div className="control btn btn-default control-save iq-trans" data-code="tooltip.saveMenu"
                     data-toggle="tooltip" data-placement="right" data-attr="title" title="Save">
                    <i className="fa fa-save iq-load"></i>

                    <div className="control-bridge"></div>
                </div>
                <div className="control btn btn-default control-load iq-trans" data-code="tooltip.loadMenu"
                     data-toggle="tooltip" data-placement="right" data-attr="title" title="Load">
                    <i className="fa fa-upload iq-load"></i>

                    <div className="control-bridge"></div>
                </div>

               
            </div>
            <div className="login-form">
                <div className="legend"><span className="iq-trans" data-code="user">User</span></div>
                <form className="form-horizontal login-panel">
                    <div className="login-error alert alert-danger">
                        <span className="iq-trans" data-code="login.fail">Login unsuccessful</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmail" className="col-xs-5 control-label"><span className="iq-trans" data-code="email">Email</span></label>

                        <div className="col-xs-7">
                            <input type="email" className="form-control input-sm iq-trans" id="inputEmail" data-code="email"
                                   data-attr="placeholder" placeholder="Email"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword" className="col-xs-5 control-label"><span className="iq-trans"
                                                                                        data-code="password">Password</span></label>

                        <div className="col-xs-7">
                            <input type="password" className="form-control input-sm iq-trans" data-code="password"
                                   data-attr="placeholder" id="inputPassword" placeholder="Password"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-xs-offset-5 col-xs-7">
                            <button className="btn btn-default control-login-submit iq-trans" data-code="login.title"
                                    data-attr="title" title="Login" type="button"><i className="iq-load"></i> <span
                                    className="iq-trans" data-code="login">Login</span></button>
                        </div>
                    </div>
                </form>
                <form className="form-horizontal logout-panel">
                    <div className="form-group">
                        <div className="col-lg-12">
                            <button className="btn btn-default control-logout-submit pull-right iq-trans"
                                    data-code="logout.title" data-attr="title" title="Logout" type="button"><span
                                    className="fa fa-power-off"></span> <span className="iq-trans"
                                                                          data-code="logout">Logout</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            
            <div className="product-instance-preview iq-load-component product-instance-template"><img alt=""/></div>
            <div className="product-instance-preview iq-load-component free-slot free-slot-template"><i
                    className="fa fa-save save-button"></i></div>
            <div className="product-instances-container">
                <div className="legend load-legend">
                    <span className="iq-trans" data-code="load">Load</span>
                </div>
                <div className="legend save-legend">
                    <span className="iq-trans" data-code="save">Save</span>
                </div>
                <div className="product-instances-wrapper">
                </div>
            </div>
        </div>

        <div className="alert-container">
            <div className="alert alert-dismissable alert-template">
                <button type="button" className="close iq-trans" data-toggle="tooltip" data-code="tooltip.closeAlert"
                        data-placement="top" data-attr="title" data-dismiss="alert" aria-hidden="true">&times;</button>
                <div className="alert-message"></div>
            </div>
        </div>
        <div>
            <div className="color-menu-container"></div>
            <div id="colorPickersContainer"></div>
        </div>
        <div className="canvas-wrapper empty"></div>
        <div className="user-input-helper">
            <div className="container-fluid" style={{position: "relative"}}>
                <div className="helper-caret"></div>
                <div className="row">
                    <div className="col-xs-3">
                        <div className="input-helper-label">
                            <span className="iq-trans" data-code="helper.labelInsert">Insert</span>
                        </div>
                    </div>
                    <div className="col-xs-3 input-helper input-helper-printing iq-trans" data-toggle="tooltip"
                         data-code="tooltip.insert.printing" data-attr="title" title="Insert printing"
                         data-href="#printings">
                        <div className="input-helper-image">
                            <span className="fa fa-picture-o"></span>
                        </div>
                        <div className="input-helper-text">
                            <span className="iq-trans" data-code="helper.printing">Printing</span>
                        </div>
                    </div>
                    <div className="col-xs-3 input-helper input-helper-user-printing iq-trans" data-toggle="tooltip"
                         data-code="tooltip.insert.userPrinting" data-attr="title" title="Insert user printing"
                         data-href="#customPrintings">
                        <div className="input-helper-image">
                            <span className="fa fa-camera-retro"></span>
                        </div>
                        <div className="input-helper-text">
                            <span className="iq-trans" data-code="helper.userPrinting">User printing</span>
                        </div>
                    </div>
                    <div className="col-xs-3 input-helper input-helper-text iq-trans" data-toggle="tooltip"
                         data-code="tooltip.insert.text" data-attr="title" title="Insert text"
                         data-href="#text">
                        <div className="input-helper-image">
                            <span className="fa fa-pencil"></span>
                        </div>
                        <div className="input-helper-text">
                            <span className="iq-trans" data-code="helper.text">Text</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="object-controls options">
            <div className="controls-only btn-group">
                <span className="btn btn-default control dragger">
                    &equiv;
                </span>
                <span id="layerUpButton" className="btn btn-default control iq-trans" data-code="tooltip.bringForward"
                      data-attr="title" data-toggle="tooltip"
                      title="Bring forward">
                    <span className="layer-up"></span>
                </span>

                <span id="layerDownButton" className="btn btn-default control iq-trans" data-code="tooltip.sendToBack"
                      data-attr="title" data-toggle="tooltip"
                      title="Send to back"
                      data-placement="top">
                    <span className="layer-down"></span>
                </span>

                <span id="flipButton" className="btn btn-default control icon-flip iq-trans" data-code="tooltip.flip"
                      data-attr="title" data-toggle="tooltip" title="Flip"
                      data-placement="top">
                    <span className="fa fa-shield"></span>
                  </span>

                <span id="duplicateButton" className="btn btn-default control iq-trans" data-code="tooltip.duplicate"
                      data-attr="title" data-toggle="tooltip"
                      title="Duplicate" data-placement="top">
                    <span className="fa fa-copy iq-load"></span>
                </span>
            </div>
        </div>


        <div className="control btn btn-default help-control">
            <div className="iq-trans fa fa-lightbulb-o" data-code="tooltip.showHelp" data-attr="title" data-toggle="tooltip"></div>
            <div className="iq-trans fa fa-times" data-code="tooltip.hideHelp" data-attr="title" data-toggle="tooltip"></div>
        </div>
        
        <div className="control-rotate btn btn-default iq-trans" data-code="tooltip.rotateCanvas" data-attr="title" data-toggle="tooltip">
            <span className="rotate-icon"></span>
        </div>

        <div className="main-canvas-overlay"></div>

        <div id="quick-nav">
            <div className="row">
                <div className="iq-trans" data-code="quick.head">Please insert on your product:</div>

                <button className="anchor-like nav-el" data-tab="printings">
                    <img className="iq-trans" data-code="quick.printing" data-attr="alt" src={`${baseUrl}creator/img/nas_motiv.png`} alt="Add printing" />
                    <div className="iq-trans" data-code="quick.printing">Add printing</div>
                </button>

                <button className="anchor-like nav-el" data-tab="customPrintings">
                    {/* eslint-disable-next-line */}
                    <img className="iq-trans" data-code="quick.custom" data-attr="alt" src={`${baseUrl}creator/img/vlastny_motiv.png`} alt="Add photo" />
                    <div className="iq-trans" data-code="quick.custom">Add photo</div>
                </button>

                <button className="anchor-like nav-el" data-tab="text">
                    <img className="iq-trans" data-code="quick.text" data-attr="alt" src={`${baseUrl}creator/img/text.png`} alt="Add text" />
                    <div className="iq-trans" data-code="quick.text">Add text</div>
                </button>

            </div>


        </div>

        <div className="anchor-like our-tip">
            <button type="button" className="close" aria-hidden="true">&times;</button>
            <span data-code="tip.heading" className="iq-trans">Náš tip</span>:
            <span data-code="tip.text" className="iq-trans">Pre uľahčenie navrhovania si vyberte jednu z preddefinovaných šablón v sekcii</span>
            <i className="fa fa-picture-o"></i> <span data-code="printingShapes" className="iq-trans">Tvary</span>.
        </div>


    </div>


</div>

    )
}