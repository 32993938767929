import React from "react"

interface TextProps {
    baseUrl: string
}
export default ({ baseUrl }: TextProps) => {
    
    return (
        <div>
    <div className="text-controls">
        <div className="col-md-12 form-group">
            <textarea id="addText" className="form-control iq-trans" data-code="text.add" data-attr="placeholder"
                      placeholder="Add text"></textarea>
        </div>

        <div className="font-family-container">
            <div className="col-sm-12 form-group">
                <div className="font-family-selector">
                    <div className="input-group dropdown font-selector iq-trans" data-code="tooltip.text.fontFamily"
                         data-attr="title" data-toggle="tooltip" title="Choose font family">
                        <span className="input-group-addon"><i className="fa fa-font"></i></span>
                        <input type="text" className="form-control"/>
                    <span className="input-group-btn">
                        <ul className="dropdown-menu"></ul>
                        <button type="button" className="btn btn-default" data-toggle="dropdown">
                            <span className="caret"></span>
                        </button>
                    </span>
                    </div>
                </div>
                <div className="font-size-selector">
                    <div className="input-group dropdown font-size-select iq-trans" data-code="tooltip.text.fontSize"
                         data-attr="title" data-toggle="tooltip" title="Choose font size">
                        <span className="input-group-addon"><i className="fa fa-text-height"></i></span>
                        <input type="text" className="form-control iq-trans" data-attr="placeholder"
                               data-code="tooltip.text.fontSize" placeholder="Choose font size"/>
                    <span className="input-group-btn">
                        <ul className="dropdown-menu"></ul>
                        <button type="button" className="btn btn-default" data-toggle="dropdown">
                            <span className="caret"></span>
                        </button>
                    </span>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 form-group" id="font-options">
                <div className="btn-group" data-toggle="buttons-checkbox">
                    <button type="button" className="btn btn-default font-weight-button iq-trans"
                            data-code="tooltip.text.bold" data-attr="title" data-toggle="tooltip"
                            title="Bold"><i className="fa fa-bold"></i></button>
                    <button type="button" className="btn btn-default font-style-button iq-trans"
                            data-code="tooltip.text.italic" data-attr="title" data-toggle="tooltip"
                            title="Italic"><i className="fa fa-italic"></i></button>
                </div>
                <div className="btn-group font-align" data-toggle="buttons-radio">
                    <button type="button" data-align="left" className="btn btn-default font-align-left active iq-trans"
                            data-code="tooltip.text.alignLeft" data-attr="title" data-toggle="tooltip"
                            title="Align left"><i className="fa fa-align-left"></i></button>
                    <button type="button" data-align="center" className="btn btn-default font-align-center iq-trans"
                            data-code="tooltip.text.alignCenter" data-attr="title" data-toggle="tooltip"
                            title="Align to center"><i className="fa fa-align-center"></i></button>
                    <button type="button" data-align="right" className="btn btn-default font-align-right iq-trans"
                            data-code="tooltip.text.alignRight" data-attr="title" data-toggle="tooltip"
                            title="Align right"><i className="fa fa-align-right"></i></button>
                    <button type="button" data-align="justify" className="btn btn-default font-align-justify iq-trans"
                            data-code="tooltip.text.alignJustify" data-attr="title" data-toggle="tooltip"
                            title="Justify"><i className="fa fa-align-justify"></i></button>
                </div>
                <div className="btn-group" id="font-colors">
                    <input type="text" value="" name="textColorPicker" id="textColorPicker" readOnly={true} />
                </div>


                <div id="text-effects">
                    <h4 className="iq-trans" data-code="text.effects">Effects:</h4>

                    <div className="btn-group" data-toggle="buttons-radio" id="text-effect-buttons">
                        <button type="button" className="btn btn-default text-normal iq-trans active" data-effect="none"
                                data-code="text.normal" data-attr="title" data-toggle="tooltip"
                                title="Normal style"><img src={`${baseUrl}creator/img/arc/icon7.png`} alt="Normal style" /></button>

                        <button type="button" className="btn btn-default text-arc-up iq-trans" data-effect="arc-down"
                            data-code="text.arc.up" data-attr="title" data-toggle="tooltip"
                            title="Circled text"><img src={`${baseUrl}creator/img/arc/icon5.png`} alt="Circle text" /></button>

                        <button type="button" className="btn btn-default text-arc-down iq-trans" data-effect = "arc-up"
                            data-code="text.arc.down" data-attr="title" data-toggle="tooltip"
                            title="Circled text"><img src={`${baseUrl}creator/img/arc/icon6.png`} alt="Circle text" /></button>
                     </div>

                    <div id="arc-wrapper">
                        <button type="button" className="btn btn-default iq-trans" id="arc-down-less"
                                data-code="text.arc.less" data-attr="title" data-toggle="tooltip"
                                title="Less circled"><img src={`${baseUrl}creator/img/arc/icon1.png`} alt="Less" />
                        </button>

                        <button type="button" className="btn btn-default iq-trans" id="arc-up-less"
                                data-code="text.arc.less" data-attr="title" data-toggle="tooltip"
                                title="Less circled"><img src={`${baseUrl}creator/img/arc/icon3.png`} alt="Less" />
                        </button>

                        <div id="arc-slider"></div>

                        <button type="button" className="btn btn-default iq-trans" id="arc-down-more"
                                data-code="text.arc.more" data-attr="title" data-toggle="tooltip"
                                title="More circled"><img src={`${baseUrl}creator/img/arc/icon2.png`} alt="More" />
                        </button>

                        <button type="button" className="btn btn-default iq-trans" id="arc-up-more"
                                data-code="text.arc.more" data-attr="title" data-toggle="tooltip"
                                title="More circled"><img src={`${baseUrl}creator/img/arc/icon4.png`} alt="More" />
                        </button>

                    </div>


                </div>

            </div>


        </div>
    </div>

</div>

    )
}