import React, { useEffect, useState } from "react"
import "./instagram.scss"
import { useAppState, useTranslations } from "../../store/hooks/app";
import { InstaPayload, Insta } from "../../store/types";
import { get } from "../../store/actions/fetch"

const Instagram = () => {
    const { config } = useAppState();
    const [ images, setImages ] = useState<Insta[]>([])
    const t = useTranslations([
        'Sleduj nás na Instagramu'
    ])

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const payload: InstaPayload = await get(`instagram/${config.lang}`)
                if (payload?.instagram) {
                    setImages(payload.instagram)
                }
            }
        })()
    }, [ config ])

    if (!images.length)
        return null;

    return (
        <div id="instagram">
            <h3>{t['Sleduj nás na Instagramu']}</h3>
            <div>
            {images.map(i =>
                <a href={i.link} rel="external" key={`insta-${i.id}`}>
                    <img src={i.thumb} alt={i.label} title={i.label} />    
                </a>
            )}
            </div>
        </div>
    )
}


export default Instagram;

