import { API_URL, STORAGE_KEY, Err } from "../../config"

const getHeaders = (): HeadersInit => {
    const headers = new Headers();
    const jwt = localStorage.getItem(STORAGE_KEY);
    if (jwt) 
        headers.set('Authorization', `Bearer ${jwt}`)
    
    headers.set('Content-Type', 'application/json')
    return headers;
}

export async function get(url: string, useOldApi: boolean = false): Promise<any> {
    const base: string = useOldApi ? API_URL.replace(/v3\/$/, '') : API_URL
    const response: Response = await fetch(`${base}${url}`, {
        headers: getHeaders()
    })

    if (response.status === 200) {
        return await response.json()
    }
    else
        throw Error(`Unable to fetch`)
}

export async function post(url: string, body: object): Promise<any> {
    const response: Response = await fetch(`${API_URL}${url}`, {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(body)
    })

    if (response.status !== 200 && response.status !== 201)
        throw new Err(response.status, response.statusText)
    else
        return response.json()
}
