import React, { useEffect, useState } from "react"
import "./order.scss"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { useParams } from "react-router-dom";
import { get } from "../../store/actions/fetch"
import { Order } from "../../store/types"

const Confirmation = () => {
    const { config } = useAppState();
    const { key } = useParams();
    const [ order, setOrder ] = useState<Order>()

    const t = useTranslations([
        'Objednávka č.',
        'Objednávka bola úspešne dokončená, ďakujeme! Teraz urobíme všetko pre to, aby si ju dostal v čo najkratšom čase. Rozpis objednávky sme Ti poslali na e-mail.',
        'Platba',
        'Doprava',
        'Stav',
        'Objednávku možno uhradiť bezhotovostným prevodom',
        'Suma',
        'Číslo účtu:',
        'IBAN:',
        'SWIFT:',
        'Tvoju objednávku začneme vybavovať hneď, ako obdržíme platbu.',
        'A ešte niečo',
        'Ak Ti do 10 minút nepríde potvrdzujúci email, skontroluj si spam či schránku reklám, over si správnosť zadaného emailu alebo plnosť Tvojej emailovej schránky.',
        'V deň, kedy objednávku odošleme, dostaneš od nás upozornenie cez email.',
        'Ak by si potreboval vedieť niečo viac, neváhaj sa na nás obrátiť či už telefonicky na čísle +421 904 920 980 alebo mailom na info@navrhnisitricko.sk.'
    ])

    useEffect(() => {
        (async () => {
            if (config?.lang && !order) {
                setOrder(await get(`order/${key}`))
            }
        })()
    })                

    if (!order)
        return null

    return (
        <div id="order-confirmation" className="page"> 
            <h3>{t['Objednávka č.']} {order.reference}</h3>
            <p>{t['Objednávka bola úspešne dokončená, ďakujeme! Teraz urobíme všetko pre to, aby si ju dostal v čo najkratšom čase. Rozpis objednávky sme Ti poslali na e-mail.']}</p>
            <ul>
                <li className={order.error ? 'error' : (order.paid ? 'paid' : 'waiting')}>{t['Stav']}: {order.state}</li>
                <li>{t['Doprava']}: {order.carrier}</li>
                <li>{t['Platba']}: {order.payment}</li>
            </ul>

            <p>{t['Tvoju objednávku začneme vybavovať hneď, ako obdržíme platbu.']}</p>
            
            {order.showBankwire && <>
                <p><strong>{t['Objednávku možno uhradiť bezhotovostným prevodom']}:</strong></p>
                <ul>
                    <li>{t['Suma']}: {order.total}</li>
                    <li>{t['Číslo účtu:']}</li>
                    <li>{t['IBAN:']}</li>
                    <li>{t['SWIFT:']}</li>
                </ul></>
            }

            <p><strong>{t['A ešte niečo']}:</strong></p>
            <ul>
                <li>{t['Ak Ti do 10 minút nepríde potvrdzujúci email, skontroluj si spam či schránku reklám, over si správnosť zadaného emailu alebo plnosť Tvojej emailovej schránky.']}</li>
                <li>{t['V deň, kedy objednávku odošleme, dostaneš od nás upozornenie cez email.']}</li>
                <li>{t['Ak by si potreboval vedieť niečo viac, neváhaj sa na nás obrátiť či už telefonicky na čísle +421 904 920 980 alebo mailom na info@navrhnisitricko.sk.']}</li>
            </ul>

        </div>
    )
}


export default Confirmation;

