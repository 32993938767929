import React from "react"
export default () => {
    
    return (
        <div>
    <div className="filter iq-load-component">
        <div className="col-xs-12">

            <div className="input-group select product-category iq-load-component iq-trans"
                 data-code="tooltip.productCategory"
                 data-attr="title" data-toggle="tooltip" data-resize="auto">
                <span className="input-group-btn">
                <input id="comboCategory" type="button" data-toggle="dropdown" style={{ textAlign: "left"} }
                       className="form-control iq-trans" data-attr="value" data-code="combo.category"
                       value="Category"/>

                    <ul className="dropdown-menu"></ul>
                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                        <span className="caret"></span>
                    </button>
                </span>
            </div>

            <div className="input-group select product-subcategory iq-load-component iq-trans hidden"
                 data-code="tooltip.productSubcategory"
                 data-attr="title" data-toggle="tooltip" data-resize="auto">
                <span className="input-group-btn">
                <input id="comboSubCategory" type="button" data-toggle="dropdown" style={{ textAlign: "left"} }
                       className="form-control iq-trans" data-attr="value" data-code="combo.subcategory"
                       value="Sub Category"/>

                    <ul className="dropdown-menu"></ul>
                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                        <span className="caret"></span>
                    </button>
                </span>
            </div>

            <div className="input-group select product-sort iq-load-component iq-trans" data-code="tooltip.productSort"
                 data-attr="title" data-toggle="tooltip" data-resize="auto">
                <span className="input-group-btn">
                <input id="comboSort" type="button" data-toggle="dropdown" style={{ textAlign: "left"} }
                       className="form-control iq-trans" data-attr="value" data-code="combo.sort"
                       value="Sort by"/>

                    <ul className="dropdown-menu"></ul>
                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                        <span className="caret"></span>
                    </button>
                </span>
            </div>
        </div>

    </div>
    <div className="product-elements loading"></div>
</div>

    )
}