import React from "react"
import "./footer.scss"
import { useTranslations, useAppState } from "../store/hooks/app"
import { Link, useLocation } from "react-router-dom"
import { MEDIA_URL, META_ABOUT, META_CONTACT } from "../config"
import logo1 from "../images/icons/logo1.png"
import logo2 from "../images/icons/logo2.png"
import logo3 from "../images/icons/logo3.png"
import logo4 from "../images/icons/logo4.png"
import logo5 from "../images/icons/logo5.png"
import logo6 from "../images/icons/logo6.png"
import logo7 from "../images/icons/logo7.png"



const Footer = () => {
    const loc = useLocation()
    const t = useTranslations([
        'Informácie',
        'O nákupe',
        'Kontakt',
        'O nás',
        'Ako si vytvoriť tričko',
        'Navrhni si tričko',
        'Novinky na stránke',
        'Galérie produktov',
        'Obchodné podmienky',
        'Platba a doručenie',
        'Často kladené otázky',
        'info@navrhnisitricko.sk',
        '+421 915 404 867',
        'Po - Pia: 8:00 - 15:30'
    ])
    
    const { config } = useAppState()

    if (loc.pathname === "/docs3pd5cb821" || !config)
        return null;

    const aboutRoute = config?.routes?.cms.find(r => r.id === META_ABOUT)
    const contactRoute = config?.routes?.meta.find(r => r.id === META_CONTACT)
    const getCMSRoute = (id: number) => config?.routes?.cms.find(r => r.id === id)

    return (
        <footer>
            <div>
                <img src={`${MEDIA_URL}logo_${config?.lang}_black.png`} alt={t['Navrhni si tričko']} />
            </div>
            <div>
                <h4>{t['Informácie']}</h4>
                <Link to={`/${contactRoute?.slug}`}>{t['Kontakt']}</Link>
                <Link to={`/${aboutRoute?.slug}`}>{t['O nás']}</Link>
                <Link to={`/${getCMSRoute(15)?.slug}`}>{t['Platba a doručenie']}</Link>
                <Link to={`/${getCMSRoute(11)?.slug}`}>{t['Obchodné podmienky']}</Link>
            </div>
            <div>
                <h4>{t['Kontakt']}</h4>
                <a href={`mailto:${t['info@navrhnisitricko.sk']}`}>{t['info@navrhnisitricko.sk']}</a>
                <a href={`tel:${t['+421 915 404 867']}`}>{t['+421 915 404 867']}</a>
                <div>{t['Po - Pia: 8:00 - 16:00']}</div>
            </div>
            <div className="partners">
                <img src={logo1} alt="Gopay" /><br />
                <img src={logo4} alt="Visa Electron" />
                <img src={logo3} alt="Visa" />
                <img src={logo2} alt="Verified by Visa" />
                <img src={logo6} alt="MasterCard" />
                <img src={logo7} alt="Maestro" />
                <img src={logo5} alt="MasterCard SecureCode" />
                <p>&copy; 2020 navrhnisitricko.sk</p>
                </div>
        </footer>
    )
}


export default Footer;

