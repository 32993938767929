import React from "react"
import "./order.scss"
import { useCartState } from "../../store/hooks/cart"
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import Loader from "../Loader";

const Order = () => {
    const { cart } = useCartState();

    if (!cart || !cart.loaded) 
        return <Loader />

    return (
        <div id="order" className={cart.items.length ? "page": undefined}> 
        {(() => { 
            switch(cart.step) {
                case 2:
                    return <StepTwo />
                case 3:
                    return <StepThree />
                case 4:
                    return <StepFour />
                default:
                    return <StepOne />
            }
        })()}
        </div>
    )
}


export default Order;

