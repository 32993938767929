import React, { useState, useEffect, useCallback } from 'react'
import "./account.scss"
import { useTranslations, useAppState } from '../../store/hooks/app';
import RegisteredUser from "./RegisteredUser"
import CustomerForm from './CustomerForm';
import { useParams, useHistory, Link } from 'react-router-dom';
import { post, get } from '../../store/actions/fetch';
import { useDispatch } from 'react-redux';
import { useCartState } from '../../store/hooks/cart';
import { STORAGE_KEY, META_ACCOUNT, DEFAULT_PRODUCT_CATEGORY } from "../../config"
import { CUSTOMER_LOGIN, Order, ORDER_CUSTOMER, ORDER_INVOICE } from '../../store/types';
import Loader from '../Loader';
import PasswordForm from './PasswordForm';
import Button from './Button';
import { useForm, FormContext } from 'react-hook-form';

interface AccountProps {
  resetPassword?: boolean
}

const Account = ({ resetPassword = false}: AccountProps) => {
    const ACCOUNT_SAVED = 1;
    const ACCOUNT_ERROR = 2;
    const ACCOUNT_DUPLICATE = 3;
    const ACTIVATION_ERROR = 4;
    const ACTIVATION_SUCCESS = 5;
    const RESET_ERROR = 6;
    const RESET_SUCCESS = 7;

    const { key, token } = useParams()
    const [ open, setOpen ] = useState<number>(1)
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ accountState, setAccountState ] = useState<number>(0)
    const [ orders, setOrders ] = useState<Order[]>([])
    const [ processing, setProcessing ] = useState<boolean>(false)
    const history = useHistory();
    
    const dispatch = useDispatch();
    const { cart } = useCartState();
    const { config, customer } = useAppState();
    const methods = useForm();

    const t = useTranslations([
      'Mám účet',
      'Vytvoriť účet',
      'Aktualizovať účet',
      'Nepodarilo sa uložiť účet. Skúste akciu opakovať, alebo vytvoriť účet znova.',
      'Nepodarilo sa overiť účet. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk.',
      'Účet s rovnakou emailovou adresou už existuje.',
      'Váš účet bol úspešne vytvorený. Na zadaný e-mail sme Vám odoslali odkaz, ktorým konto aktivujete.',
      'Váš účet bol úspešne uložený.',
      'Moje objednávky',
      'Zmeniť údaje',
      'Zaplatené',
      'Nezaplatené',
      'Odhlásiť na všetkých zariadeniach',
      'Odhlásiť',
      'Prihlásenie sa nepodarilo. Skontrolujte zadané údaje.',
      'Obnoviť zabudnuté heslo',
      'Tu si môžete obnoviť svoje heslo',
      'Váš účet bol úspešne obnovený.',
      'Nepodarilo sa obnoviť účet. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk.',
      'Vytvorte svoju prvú objednávku práve teraz!'
    ])

    /** Load customer data */
    const loadCustomer = useCallback(async () => {
      setLoading(true)

      const _data = await get(`customer/${config?.lang}`)

      dispatch({
        type: ORDER_CUSTOMER,
        data: {
          name: _data.name,
          email: _data.email,
          phone: _data.phone,
          newsletter: _data.newsletter === 1
        }
      })

      dispatch({
        type: ORDER_INVOICE,
        data: {
          street: _data.street,
          city: _data.city,
          zip: _data.postcode,
          country: _data.country
        }
      })
      
      setOrders(_data.orders)
      setLoading(false)
    }, [ dispatch, config ]);

    useEffect(() => {
      (async () => {
        
        /* Verify users account */
        if (!resetPassword && key && token) {
          try {
            const auth = await post(`account/activate`, {
              token,
              customer: key
            });

            if (auth && auth.token) {
              localStorage.setItem(`${STORAGE_KEY}`, auth.token)
              
              dispatch({
                type: CUSTOMER_LOGIN
              })

              const meta = config?.routes.meta.find(r => r.id === META_ACCOUNT)?.slug
                if (meta)
                  history.replace(`/${meta}`)

              setAccountState(ACTIVATION_SUCCESS)
            }
          }
          catch (e) {
            setAccountState(ACTIVATION_ERROR)
            setLoading(false)
          }
        }

        /* Load logged customer */
        if (customer) 
            await loadCustomer()
        else 
          setLoading(false)

      })()
    }, [ key, token, cart, dispatch, customer, loadCustomer, resetPassword, config, history ])

  if (loading || !config)
    return <Loader />;

  if (resetPassword) {
    return <div id="reset-account" className="page">
              <h4>{t['Tu si môžete obnoviť svoje heslo']}</h4>
    
            { accountState === RESET_ERROR && <div className="errored">
              {t['Nepodarilo sa obnoviť účet. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk.']}
            </div>}
            
            <PasswordForm onSubmit={async data => {
              try {
                const auth = await post(`account/password`, Object.assign(data, { key, token }))
                if (auth && auth.token)
                  localStorage.setItem(`${STORAGE_KEY}`, auth.token)

                dispatch({
                    type: CUSTOMER_LOGIN
                })

                const meta = config.routes.meta.find(r => r.id === META_ACCOUNT)?.slug
                if (meta)
                  history.replace(`/${meta}`)
                  
                setAccountState(RESET_SUCCESS)
              }
              catch (e) {
                setAccountState(RESET_ERROR);
              }
          }} />
          </div>
  }

  if (!customer)
    return (
      <div id="sign" className="page">
        
          { accountState === ACTIVATION_ERROR && <div className="errored">
              {t['Nepodarilo sa overiť účet. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk.']}
          </div>}

          { accountState === ACCOUNT_DUPLICATE && <div className="errored">
              {t['Účet s rovnakou emailovou adresou už existuje.']}
          </div>}

          { accountState === ACCOUNT_ERROR && <div className="errored">
              {t['Nepodarilo sa uložiť účet. Skúste akciu opakovať, alebo vytvoriť účet znova.']}
          </div>}

          { accountState === ACCOUNT_SAVED && <div className="success">
              {t['Váš účet bol úspešne vytvorený. Na zadaný e-mail sme Vám odoslali odkaz, ktorým konto aktivujete.']}
          </div>}

          <div>
              <div className={open === 1 ? 'active' : undefined}>
                  <button className="anchor-like" onClick={setOpen.bind(null, open === 1 ? 0 : 1)}>{t['Mám účet']}</button>
                  <RegisteredUser onSubmit={async data => {
                        const auth = await post(`account/login`, data)
                        if (auth && auth.token)
                          localStorage.setItem(`${STORAGE_KEY}`, auth.token)

                        dispatch({
                            type: CUSTOMER_LOGIN
                        })
                        setOpen(1)
                  }} />
              </div>

              <div className={open === 2 ? 'active' : undefined}>
                <button className="anchor-like" onClick={setOpen.bind(null, open === 2 ? 0 : 2)}>{t['Vytvoriť účet']}</button>
                    <FormContext {...methods}>
                      <div>
                        <CustomerForm showPassword={true} showGDPR={true} />

                        <Button processing={processing} onClick={methods.handleSubmit(async data => {
                          try {
                            setProcessing(true)
                            await post(`account/${config.lang}/add`, data)
                            setAccountState(ACCOUNT_SAVED)
                          }
                          catch (e) {
                            if (e.code === 409)
                              setAccountState(ACCOUNT_DUPLICATE)
                            else
                              setAccountState(ACCOUNT_ERROR)
                          }
                          setProcessing(false)
                        })} title={t['Vytvoriť účet']} />
                        
                      </div>
                    </FormContext>
                  
              </div>
          </div>
      </div>
    );

  const creatorRoute = config.routes.products.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
  return <div id="account" className="page">

      { accountState === ACCOUNT_SAVED && <div className="success">
              {t['Váš účet bol úspešne uložený.']}
        </div>}

      { accountState === ACCOUNT_ERROR && <div className="errored">
              {t['Nepodarilo sa uložiť účet. Skúste akciu opakovať, alebo vytvoriť účet znova.']}
        </div>}

      { accountState === RESET_SUCCESS && <div className="success">
            {t['Váš účet bol úspešne obnovený.']}
        </div>}

      <div>
              <div className={open === 1 ? 'active' : undefined}>
                  <button className="anchor-like" onClick={setOpen.bind(null, open === 1 ? 0 : 1)}>{t['Moje objednávky']}</button>
                  {orders &&
                    <table id="orders">
                      <tbody>
                      {orders.map(o => 
                        <tr key={`order-${o.reference}`} className={o.error ? "errored" : (o.paid ? "paid": undefined)}>
                          <td>{o.date}</td>
                          <td>{o.reference}</td>
                          <td>{o.state}</td>
                          <td>{o.delivery}</td>
                          <td>{o.address}</td>
                          <td>{o.amount}</td>
                          <td title={o.paid ? t['Zaplatené']: t['Nezaplatené'] }>{o.paid ? t['Zaplatené']: t['Nezaplatené']}</td>
                        </tr>  
                      )}
                      </tbody>
                    </table>
                  }
                  {orders.length === 0 && <div className="no-orders">
                      <Link className="button" to={`/${creatorRoute?.slug}`}>{t['Vytvorte svoju prvú objednávku práve teraz!']}</Link>
                  </div>}
              </div>

              <div className={open === 2 ? 'active' : undefined}>
                <button className="anchor-like" onClick={setOpen.bind(null, open === 2 ? 0 : 2)}>{t['Zmeniť údaje']}</button>
                <FormContext {...methods}>

                  <div>
                    <CustomerForm showPassword={true} showOldPassword={true} disableEmail={true} />
                    <Button processing={processing} onClick={methods.handleSubmit(async data => {
                            try {
                              setProcessing(true)
                              await post(`account/edit/${config.lang}`, data)
                              setAccountState(ACCOUNT_SAVED)
                            }
                            catch (e) {
                              setAccountState(ACCOUNT_ERROR)
                            }
                            setProcessing(false)
                      })} title={t['Aktualizovať účet']} />
                  </div>  
                </FormContext>
                
              </div>

              <div className={open === 3 ? 'active' : undefined}>
                <button className="anchor-like" onClick={setOpen.bind(null, open === 3 ? 0 : 3)}>{t['Odhlásiť na všetkých zariadeniach']}</button>
                
                <div>
                  <button className="logout" onClick={() => {
                    (async () => {

                      setLoading(true)
                      
                      await post(`account/logout`, {})
                    
                      window.location.reload()
                    })()
                  }}>{t['Odhlásiť']}</button>
                </div>
              </div>
          </div>
  </div>
}

export default Account;