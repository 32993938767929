import React from 'react';
import { useTranslations } from '../store/hooks/app';
import loadingImg from "../images/icons/loading.png"
import "./loader.scss"

function Loader() {
    const t = useTranslations([
        'Načítá sa ...'
    ])

  return (
    <div className="loader">
        <img src={loadingImg} alt={t['Načítá sa ...']} />
    </div>
  );
}

export default Loader;