import React from "react"
import "./categories.scss"
import { useTranslations, useAppState } from "../../store/hooks/app";
import { Link } from "react-router-dom"

import menImg from "../../images/categories_men.png"
import womenImg from "../../images/categories_women.png"
import kidsImg from "../../images/categories_kids.png"
import giftsImg from "../../images/categories_gifts.png"

const Categories = () => {
    const t = useTranslations([
        'Pre mužov',
        'Pre ženy',
        'Pre deti',
        'Dárčeky',
        'Tričká',
        'Mikiny',
        'Tielka',
        'Polokošeľe',
        'Športové tričká',
        'Tričká s dlhým rukávom',
        'Hrnčeky',
        'Vankúše',
        'Puzzle',
        'Zástery',
        'Magnetky',
        'Kryty na mobil'
    ]);

    const { config } = useAppState()

    if (!config)
        return null;


    const getRoute = (id: number) => config?.routes?.categories.find(r => r.id === id)
   

    return (
        <div id="categories">
            <div></div>
            <div>
                <div>
                    <img src={menImg} alt={t['Pre mužov']} />
                    <h3>{t['Pre mužov']}</h3>
                    <ul>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Tričká']}</Link></li>
                        <li><Link to={`/${getRoute(260)?.slug}`}>{t['Mikiny']}</Link></li>
                        <li><Link to={`/${getRoute(261)?.slug}`}>{t['Tielka']}</Link></li>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Polokošeľe']}</Link></li>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Tričká s dlhým rukávom']}</Link></li>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Športové tričká']}</Link></li>
                    </ul>
                </div>
                <div>
                    <img src={kidsImg} alt={t['Pre deti']} />
                    <h3>{t['Pre deti']}</h3>
                    <ul>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Tričká']}</Link></li>
                        <li><Link to={`/${getRoute(260)?.slug}`}>{t['Mikiny']}</Link></li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <img src={womenImg} alt={t['Pre ženy']} />
                    <h3>{t['Pre ženy']}</h3>
                    <ul>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Tričká']}</Link></li>
                        <li><Link to={`/${getRoute(260)?.slug}`}>{t['Mikiny']}</Link></li>
                        <li><Link to={`/${getRoute(261)?.slug}`}>{t['Tielka']}</Link></li>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Polokošeľe']}</Link></li>
                        <li><Link to={`/${getRoute(259)?.slug}`}>{t['Tričká s dlhým rukávom']}</Link></li>
                    </ul>
                </div>
                <div>
                    <img src={giftsImg} alt={t['Dárčeky']} />
                    <h3>{t['Dárčeky']}</h3>
                    <ul>
                        <li><Link to={`/${getRoute(262)?.slug}`}>{t['Kryty na mobil']}</Link></li>
                        <li><Link to={`/${getRoute(258)?.slug}`}>{t['Hrnčeky']}</Link></li>
                        <li><Link to={`/${getRoute(258)?.slug}`}>{t['Vankúše']}</Link></li>
                        <li><Link to={`/${getRoute(258)?.slug}`}>{t['Puzzle']}</Link></li>
                        <li><Link to={`/${getRoute(258)?.slug}`}>{t['Zástery']}</Link></li>
                        <li><Link to={`/${getRoute(258)?.slug}`}>{t['Magnetky']}</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default Categories;

