import { 
  OrderActions, OrderState, ORDER_INVOICE, ORDER_DELIVERY, ORDER_CUSTOMER, ORDER_CARRIER, CUSTOMER_LOGOUT
} from "../types"

const defaultCustomer = {
  name: '',
  email: '',
  phone: '',
  newsletter: false
}
const defaultAddress = {
  street: '',
  city: '',
  zip: '',
  country: ''
}

export function orderReducer (state: OrderState = {
    carrier: undefined,
    payment: undefined,
    delivery: { ...defaultAddress },
    invoice: { ...defaultAddress },
    customer: { ...defaultCustomer }
}, action: OrderActions): OrderState {
    
    switch (action.type) {

      case ORDER_INVOICE:
        return Object.assign({}, state, {
          invoice: Object.assign(state.invoice, action.data) 
        })

      case ORDER_DELIVERY:
        return Object.assign({}, state, {
          delivery: Object.assign(state.delivery, action.data) 
        })

      case ORDER_CUSTOMER:
        return Object.assign({}, state, {
          customer: Object.assign(state.customer, action.data) 
        })

      case CUSTOMER_LOGOUT:
        return Object.assign({}, state, {
          customer: { ...defaultCustomer },
          invoice: { ...defaultAddress },
          delivery: { ...defaultAddress }
        })

      case ORDER_CARRIER:
        
        return Object.assign({}, state, { carrier: action.carrier })

      default:
        return state
    }
}
