import React from "react"
import "./contact.scss"
import { useTranslations } from "../store/hooks/app"
import mailIcon from "../images/icons/mail.png"
import phoneIcon from "../images/icons/phone.png"


const Contact = () => {
    const t = useTranslations([
        'Kontakt',
        'info@navrhnisitricko.sk',
        '+421 915 404 867',
        'Prevádzkovateľ',
        'Naminy Trade s.r.o.',
        'Sídlo: Vodná 293/6',
        '94501 Komárno',
        'Slovenská republika',
        'IČO: 50 664 654',
        'Bankové spojenie',
        'Adresa pobočky',
        'Sme Ti k dispozícii v pracovné dni od 07:30 do 15:30 hod.',
        'Číslo účtu:',
        'IBAN:',
        'SWIFT:'
    ])

    return (
        <div id="contact" className="page">
            <div>
                <iframe title={t['Adresa pobočky']} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1340.9143323652972!2d18.133608058178385!3d47.76537759769518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476bad4d9b0e9d4b%3A0xa7d9ad6c2d732cc0!2sVodn%C3%A1%206%2C%20945%2001%20Kom%C3%A1rno%2C%20Slovakia!5e0!3m2!1sen!2scz!4v1588166663997!5m2!1sen!2scz" frameBorder="0" style={{border:0}}></iframe>
            </div>
            <div>
                <p>{t['Sme Ti k dispozícii v pracovné dni od 08:00 do 16:00 hod.']}</p>
                <a href={`mailto:${t['info@navrhnisitricko.sk']}`}><img src={mailIcon} alt={t['Kontakt']} />{t['info@navrhnisitricko.sk']}</a><br />
                <a href={`tel:${t['+421 915 404 867']}`}><img src={phoneIcon} alt={t['Kontakt']} />{t['+421 915 404 867']}</a><br />
                <div>
                    <div>
                        <h4>{t['Prevádzkovateľ']}</h4>
                        {t['Naminy Trade s.r.o.']}<br />
                        {t['Sídlo: Vodná 293/6']}<br />
                        {t['94501 Komárno']}<br />
                        {t['Slovenská republika']}<br />
                        {t['IČO: 50 664 654']}<br />
                        
                    </div>
                    <div>
                        <h4>{t['Bankové spojenie']}</h4>
                        {t['Číslo účtu:']}<br />
                        {t['IBAN:']}<br />
                        {t['SWIFT:']}<br />
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Contact;

