import React, { useState, useEffect } from "react"
import "./step-two.scss"
import Buttons from "./Buttons"
import { useForm, FormContext } from "react-hook-form"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { get, post } from "../../store/actions/fetch"
import { useCartState } from "../../store/hooks/cart"
import CustomerForm from "../Account/CustomerForm"
import RegisteredUser from "../Account/RegisteredUser"
import { useDispatch, useSelector } from "react-redux"
import { OrderAddress, ORDER_CUSTOMER, ORDER_INVOICE, ORDER_DELIVERY, CUSTOMER_LOGIN, State } from "../../store/types"
import { STORAGE_KEY } from "../../config"
import Loader from "../Loader"

const StepTwo = () => {

    const { config, customer } = useAppState()
    const { goForward } = useCartState()
    const [ open, setOpen ] = useState<number>(2)
    const [ duplicate, setDuplicate ] = useState<boolean>(false)
    const [ authError, setAuthError ] = useState<boolean>(false)
    const [ processing, setProcessing ] = useState<boolean>(false)
    const [ loading, setLoading ] = useState<boolean>(true)
    const showNewsletter = useSelector((state:State) => !state.order.customer.newsletter)
    
    const methods = useForm();
    const dispatch = useDispatch();
    
    const t = useTranslations([
        'Mám účet',
        'Nakupujem prvýkrát',
        'Prihlásenie sa nepodarilo. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk',
        'Účet s rovnakým emailom už existuje. Prihláste sa, alebo obnovte zabudnuté heslo.'
    ])
    
    const setValue = methods.setValue

    useEffect(() => {
        (async () => {
            if (customer) {
                
                const info: OrderAddress = await get(`address`)
                if (info.customer?.email) {
                    dispatch({
                        type: ORDER_CUSTOMER,
                        data: info.customer
                    })
                }
                
                if (info.invoice?.country) {
                    dispatch({
                        type: ORDER_INVOICE,
                        data: info.invoice
                    })
                    
                }

                if (info.delivery?.country)
                    dispatch({
                        type: ORDER_DELIVERY,
                        data: info.delivery
                    })
                if (info.delivery.street !== null) {
                    setValue('delivery', true)
                }
                setLoading(false)
            }
            setLoading(false)
            })()
        }, [ customer, dispatch, setValue ])
    
        if (!config || loading)
            return <Loader />;
   
    return (
        <>
            <FormContext {...methods}>
            <div id="order-form">
                <div>
                    {customer && <> 
                        <CustomerForm showDelivery={true} showNewsletter={showNewsletter} />
                    </>}
                    {!customer && <>
                        <div className={open === 1 ? 'active' : undefined}>
                            <button className="anchor-like" onClick={setOpen.bind(null, open === 1 ? 0 : 1)}>{t['Mám účet']}</button>
                            <RegisteredUser onSubmit={async data => {
                                const auth = await post(`account/login`, data)
                                if (auth && auth.token) {
                                    localStorage.setItem(`${STORAGE_KEY}`, auth.token)
                                
                                    dispatch({
                                        type: CUSTOMER_LOGIN
                                    })
                                }
                            }} />
                        </div>

                        <div className={open === 2 ? 'active' : undefined}>
                            <button className="anchor-like" onClick={setOpen.bind(null, open === 2 ? 0 : 2)}>{t['Nakupujem prvýkrát']}</button>
                            
                            { authError && <div className="errored">
                                {t['Prihlásenie sa nepodarilo. Skúste akciu opakovať, alebo nás kontaktujte na info@navrhnisitricko.sk']}
                            </div>}

                            { duplicate && <div className="errored">
                                {t['Účet s rovnakým emailom už existuje. Prihláste sa, alebo obnovte zabudnuté heslo.']}
                            </div>}
                            <CustomerForm showDelivery={true} showGDPR={true} />
                        </div>
                    </>}
                </div>
            </div>

            <Buttons processing={processing} handleForward={methods.handleSubmit(async data => {
                try {
                    setProcessing(true)
                    setDuplicate(false)
                    setAuthError(false)

                    await post(`cart/${config.lang}`, data)
                    
                    const { name, email, phone, newsletter, street, city, zip, country } = data;
                    
                    dispatch({ type: ORDER_CUSTOMER, data: { name, email, phone, newsletter } })
                    dispatch({ type: ORDER_INVOICE, data: { street, city, zip, country } })
                    
                    if (data.delivery === true) {
                        const { street_2, city_2, zip_2, country_2 } = data
                        dispatch({ type: ORDER_DELIVERY, data: { street: street_2, city: city_2, zip: zip_2, country: country_2 } })
                    }

                    goForward()
                } catch (e) {
                    if (e.code === 409)
                        setDuplicate(true)
                    else
                        setAuthError(true)  
                    setProcessing(false)
                }
            })} />
            
            </FormContext>
        </>
    )
}


export default StepTwo
