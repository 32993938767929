import React, { useEffect, useState, useRef } from "react"
import "./designs.scss"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { get } from "../../store/actions/fetch";
import { ROOT_PRINTING_CATEGORY, DEFAULT_PRODUCT_CATEGORY } from "../../config";
import { DesignPayload } from "../../store/types";
import { Link } from "react-router-dom"
import { Swipeable } from "react-swipeable"

interface DesignLinkProps {
    image: string
    link: string
}

const DesignLink = ({ image, link }: DesignLinkProps) => {
    const t = useTranslations([
        'Tričká, mikiny, hrnčeky, vankúše a ďalšie s originálnou potlačou',
        'Chcem tento motív'
    ]);
    
    return <Link to={link}>
        <img src={image} alt={t['Tričká, mikiny, hrnčeky, vankúše a ďalšie s originálnou potlačou']} />
        <div className="overlay">{t['Chcem tento motív']}</div>
    </Link>
}

const Designs = () => {
    const [ designs, setDesigns ] = useState<DesignPayload[]>([])
    const slider = useRef<HTMLDivElement>(null)
    const { config } = useAppState();
    
    const t = useTranslations([
        'Vyber si z viac ako 2000 motívov',
        'Tričká, mikiny, hrnčeky, vankúše a ďalšie s originálnou potlačou',
        'Zobraziť ďalšie motívy',
        'Obľúbené kategórie',
        'vtipné hlášky',
        'láska',
        'sviatky',
        'detské motívy',
        'Chcem tento motív'
    ]);

    const creatorRoute = config?.routes?.products.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
    

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const payload: DesignPayload[] = await get(`designs/${ROOT_PRINTING_CATEGORY}/${config.lang}`)
                setDesigns(payload.concat(payload))
            }
        })()
    }, [ config ])
    
    let carousel: JSX.Element[] = [];
    let i: number = 0
    
    while (i < designs.length) {
        const row = designs.slice(i, i + 3);
        if (row.length === 3) {
            carousel.push(
            <div className="flex-row" key={`designs-${i}-3`}>
                <div>
                    <DesignLink image={row[0].url} link={`/${creatorRoute?.slug}/${row[0].id}/${row[0].link}`} />
                </div>     
                <div>
                    <DesignLink image={row[1].url} link={`/${creatorRoute?.slug}/${row[1].id}/${row[1].link}`} />
                    <DesignLink image={row[2].url} link={`/${creatorRoute?.slug}/${row[2].id}/${row[2].link}`} />
                </div>       
            </div>
            )
        }

        const column = designs.slice(i + 3, i + 7);
        
        if (column.length === 4) {
            carousel.push(
            <div className="flex-column" key={`designs-${i}-4`}>
                <div>
                    <DesignLink image={column[0].url} link={`/${creatorRoute?.slug}/${column[0].id}/${column[0].link}`} />
                    <DesignLink image={column[1].url} link={`/${creatorRoute?.slug}/${column[1].id}/${column[1].link}`} />
                 </div>     
                <div>
                    <DesignLink image={column[2].url} link={`/${creatorRoute?.slug}/${column[2].id}/${column[2].link}`} />
                    <DesignLink image={column[3].url} link={`/${creatorRoute?.slug}/${column[3].id}/${column[3].link}`} />
                </div>       
            </div>
            )
        }

        i+=7;
    }

    const easeOutQuint = (elapsed: number, initialValue: number, amountOfChange: number, duration: number): number => {
        return amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * elapsed * elapsed * elapsed + 1) + initialValue;
    }
    
    const scroll = (initial: number, delta: number, startTime: number, duration: number = 600) => {
        
        if (null !== slider.current) {
            const elapsed = Date.now() - startTime;
            const left = easeOutQuint(elapsed, initial, delta, duration);
            slider.current.scroll({ left })
            
            if (elapsed < duration)
                requestAnimationFrame(scroll.bind(null, initial, delta, startTime, duration))
        }
    }

    useEffect(() => {
        if (slider.current && slider.current.scrollLeft === 0) {
            scroll(0, slider.current.scrollWidth / 2 + document.body.clientWidth / 2, Date.now())
        }
    })



    return (
        <div id="designs">  
            <h2>{t['Vyber si z viac ako 2000 motívov']}</h2>
            <Swipeable onSwiped={e => {
                if (null !== slider.current) {
                    const delta = e.velocity * (e.dir === "Left" ? 1 : -1) * (slider.current.clientWidth / 2)
                    scroll(slider.current.scrollLeft, delta, Date.now())
                }
                
            }}>
                <div ref={slider} onMouseMove={(e) => {
                    if (null != slider.current) {
                        if (e.pageX < slider.current.clientWidth / 5)
                            scroll(slider.current.scrollLeft, -30, Date.now())
                        else if (e.pageX > (slider.current.clientWidth / 5 * 4))
                            scroll(slider.current.scrollLeft, 30, Date.now())
                    }
                }}>
                    {carousel}
                </div>
            </Swipeable>
           {/* TODO: What instead of this nav?
            <div>
                <Link to={`/${route?.slug}`} className="button">{t['Zobraziť ďalšie motívy']}</Link>
                <p>{t['Obľúbené kategórie']}: 
                    <Link to={`${route?.slug}?id=29,59`}>{t['vtipné hlášky']}</Link>
                    -<Link to={`${route?.slug}?id=36`}>{t['sviatky']}</Link>
                    -<Link to={`${route?.slug}?id=52,26`}>{t['láska']}</Link>
                    -<Link to={`${route?.slug}?id=56,60,30`}>{t['detské motívy']}</Link>
                </p>
           </div> */}   
        </div>
    )
}


export default Designs;

