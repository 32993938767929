import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { State, CART_FORWARD, CART_BACKWARDS } from "../types"
import { changeItem } from "../actions/cart"
import { useAppState } from "./app";

export const useCartState = () => {
    const dispatch = useDispatch();
    const { config } = useAppState();
    const cart = useSelector((state: State) => state.cart, shallowEqual)

    const goForward = () => {
        dispatch({ type: CART_FORWARD })
    }

    const goBackwards = () => {
        dispatch({ type: CART_BACKWARDS })
    }
    
    const changeQuantity = async (id: number, amount: number) => {
        if (config?.lang) {
            dispatch(await changeItem(config.lang, id, amount))
        }
    }

    return {
        cart,
        changeQuantity,
        goBackwards,
        goForward
    };
}
    