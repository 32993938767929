import { 
  CartState, CartActions, CART_LOADED, CART_FORWARD, CART_BACKWARDS, CART_LOADING, CONFIG_LOADED
} from "../types"

export function cartReducer (state: CartState = {
  id: undefined,
  key: undefined,
  loaded: false,
  step: 1,
  total: '',
  notax: '',
  voucher: null,
  shipping: '',
  items: [],
}, action: CartActions): CartState {
    
    switch (action.type) {
      case CART_LOADED:
        return Object.assign({}, state, action, { loaded: true })

      case CONFIG_LOADED:
          return Object.assign({}, state, action.config.cart, { loaded: true })
  
      case CART_LOADING:
        return Object.assign({}, state, action, { loaded: undefined })

      case CART_FORWARD:
        return Object.assign({}, state, { step: Math.min(state.step + 1, 4) })

      case CART_BACKWARDS:
        return Object.assign({}, state, { step: Math.max(state.step - 1, 1) })

      default:
        return state
    }
}
