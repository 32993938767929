import React, { useEffect, useState } from "react"
import "./cms.scss"
import { useAppState } from "../../store/hooks/app"
import { get } from "../../store/actions/fetch";

interface CmsProps {
    id: number
}

const Cms = ({ id }: CmsProps) => {
    
    const { config } = useAppState();
    const [ content, setContent ] = useState<string>()
    
    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const cms = await get(`cms/${id}/${config.lang}`)
                if (cms && cms.content) {
                    setContent(cms.content);
                }
            }
        })()
    }, [ config, id ])

    if (!content)
        return null

    return (
        <div id="cms" className="page" dangerouslySetInnerHTML={{ __html: content}}></div>
    )
}


export default Cms;

