import React, { useEffect, useState } from "react"
import "./product.scss"
import { Category, CategoryDesign, ProductDetail } from "../../store/types";
import { useAppState, useTranslations, useQuery } from "../../store/hooks/app";
import { get } from "../../store/actions/fetch"
import { Link, useHistory } from "react-router-dom";
import Loader from "../Loader";
import DrawIcon from "../../images/icons/drawing.png"
import { DEFAULT_PRINTING_CATEGORY, ROOT_PRINTING_CATEGORY, API_URL, MEDIA_URL } from "../../config";

interface ProductProps {
    id: number | undefined
}

const Product = ({ id }: ProductProps) => {

    const [ categories, setCategories ] = useState<Category[]>([])
    const queryIds = useQuery().get('id')?.split(',').map(i => Number(i)) || [ DEFAULT_PRINTING_CATEGORY ]
    const color = useQuery().get('c') || 'default'

    const [ active, setActive ] = useState<number[]>(queryIds.length ? [ ...queryIds ] : [ ])
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ loadingDesigns, setLoadingDesigns ] = useState<boolean>(true);
    const [ product, setProduct ] = useState<ProductDetail>();
    const [ picture, setPicture ] = useState<string>();
    const [ designs, setDesigns ] = useState<CategoryDesign[]>([])

    const history = useHistory();
    const { config } = useAppState();
    const oldApi = API_URL.replace(/v3\/$/, (config?.lang ? config?.lang : 'sk'))
    const t = useTranslations([
        'Tabuľka veľkostí',
        'Pridať text a fotku',
        'Cena od',
        'vlastny-text',
        'fotka',
        'Alebo vyber z bohatej ponuky našich motívov',
        'Chcem tento motív'
    ])

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const payload = await get(`product/${config.lang}/${id}/${color}`)
                if (payload) {
                    setPicture(`${oldApi}/file/image/${payload.ipa}/text`)
                    setProduct(payload)
                    setLoading(false)
                }
            }
        })()
    }, [ config, id, color, oldApi ])

    useEffect(() => {
        setTimeout(() => {
            if (active.length) {
                history.replace({
                    search: `?c=${color}&id=${active.join(',')}`
                });

                if (config?.lang) {
                    (async() => {
                        setLoadingDesigns(true)
                        const categories = await get(`${config.lang}/category/${ROOT_PRINTING_CATEGORY}/categories`, true)
                        if (categories?.printings) {
                            setCategories(categories.printings)
                        }
                        const payload: CategoryDesign[] = await get(`designs/${active.join('_')}/${config.lang}`)
                        if (payload) {
                            setDesigns(payload)
                            setLoadingDesigns(false)
                        } 
                    })()
                }
            }
        }, loading ? 2000 : 0);

    }, [ active, history, config, color, loading ])

    if (!product || loading)
        return <Loader />

    let usedIpas: number[] = []
    const designDivs = loadingDesigns ? <Loader /> :
        designs.map(d => {
            let color = product.ipas.find(i => !usedIpas.includes(i))
            if (!color) {
                color = product.ipas[0]
                usedIpas = []
            }
            usedIpas.push(color)

            return <Link key={`design-${d.id}`} to={`/${product.slug}/${d.id}/${d.link}/${color}`}>
                <img src={`${oldApi}/file/image/${color}/${d.id}`} alt={d.name} />
                <div className="overlay">{t['Chcem tento motív']}</div>
            </Link>
        })

    return (
        <div id="product" className="page">
            <div>
                <div>
                    <h2>{product.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html: product.desc }}></p>
                    
                    {product.table && <img src={`${MEDIA_URL}p/${product.table}`} alt={t['Tabuľka veľkostí']} />}
                    
                    <div className="thumbs">
                        {product.images && product.images.map((i, j) => <span key={`th-${j}`}><img src={`${MEDIA_URL}p/${i}`} alt={product.name} onClick={setPicture.bind(null, `${MEDIA_URL}p/${i}` )} /></span>)}
                        {product.previews && product.previews.map((i, j) => <span key={`fp-${j}`}><img src={`${MEDIA_URL}p/${i}`} alt={product.name} onClick={setPicture.bind(null, `${MEDIA_URL}p/${i}` )} /></span>)}
                    </div>

                    <div className="action">
                        <Link to={`/${product.slug}/${t['vlastny-text']}/${t['fotka']}/${product.ipa}`}>
                            <img src={DrawIcon} alt={t['Pridať text a fotku']} />
                            {t['Pridať text a fotku']}
                        </Link>
                        {t['Cena od']} <span>{product.price}</span>
                    </div>
                </div>
                <div>
                    {picture ? <img src={picture} alt={product.name} /> : <Loader />}
                </div>
            </div>
            <div><h4>{t['Alebo vyber z bohatej ponuky našich motívov']}:</h4></div>            
            <div id="designs">
            <nav>
                <ul>
                    {categories.map(c => {
                        return <li key={`cat-${c.id}`} onMouseUp={() => {

                                if (active.includes(c.id)) {
                                    const i = active.indexOf(c.id)
                                    setActive([ ...active.slice(0, i), ...active.slice(i + 1) ])
                                }
                                else {
                                    setActive([ ...active, c.id ])
                                }
                            }
                            }>
                            <label>
                                <input defaultChecked={c.id === id} type="checkbox" />
                                <span className={active.includes(c.id) ? 'checked' : undefined} />
                                {c.name}
                            </label>
                        </li>
                    })}
                </ul>
            </nav>

            <div>
                {designDivs}
            </div>
            </div>

        </div>
    )
}


export default Product;

