import React, { useEffect, useState } from "react"
import "./motives.scss"
import { Category, CategoryDesign } from "../../store/types";
import { useAppState, useTranslations, useQuery } from "../../store/hooks/app";
import { ROOT_PRINTING_CATEGORY, DEFAULT_PRODUCT_CATEGORY } from "../../config"
import { get } from "../../store/actions/fetch"
import { Link, useHistory } from "react-router-dom";
import Loader from "../Loader";

interface CategoryProps {
    id: number
}

const Motives = ({ id }: CategoryProps) => {
    const [ categories, setCategories ] = useState<Category[]>([])
    const queryIds = useQuery().get('id')?.split(',').map(i => Number(i)) || []
    const [ active, setActive ] = useState<number[]>(queryIds.length ? [ ...queryIds ] : [ id ])
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ designs, setDesigns ] = useState<CategoryDesign[]>([])
    const [ hamburgerActive, showMobileMenu ] = useState(false);

    const history = useHistory();
    const { config } = useAppState();
    const t = useTranslations([
        'Kategórie motívov',
        'Chcem tento motív'
    ])

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const payload = await get(`${config.lang}/category/${ROOT_PRINTING_CATEGORY}/categories`, true)
                if (payload?.printings) {
                    setCategories(payload.printings)
                }
            }
        })()
    }, [ config ])

    useEffect(() => {
        if (active.length) {
            history.replace({
                search: `?id=${active.join(',')}`
            });

            if (config?.lang) {
                (async() => {
                    setLoading(true)
                    const payload: CategoryDesign[] = await get(`designs/${active.join('_')}/${config.lang}`)
                    if (payload) {
                        setDesigns(payload)
                        setLoading(false)
                    } 
                })()
            }
        }

    }, [ active, history, config ])

    if (!categories)
        return null;

    const toggleMobileMenu = (): void => {
        showMobileMenu(!hamburgerActive)
    }
    
    const route = config?.routes.products.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
    const hamburgerCls = hamburgerActive ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin";
    const navCls = hamburgerActive ? "active" : undefined;

    return (
        <div id="motives">
            <nav className={navCls}>
                <h4 onClick={toggleMobileMenu}>{t['Kategórie motívov']}</h4>
                <button className={hamburgerCls} type="button" onClick={toggleMobileMenu}>
                    <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                    </span>
                </button>
                <ul>
                    {categories.map(c => {
                        return <li key={`cat-${c.id}`} onMouseUp={() => {

                                if (active.includes(c.id)) {
                                    const i = active.indexOf(c.id)
                                    setActive([ ...active.slice(0, i), ...active.slice(i + 1) ])
                                }
                                else {
                                    setActive([ ...active, c.id ])
                                }
                            }
                            }>
                            <label>
                                <input defaultChecked={c.id === id} type="checkbox" />
                                <span className={active.includes(c.id) ? 'checked' : undefined} />
                                {c.name}
                            </label>
                        </li>
                    })}
                </ul>
            </nav>

            <div>
                {loading ? <Loader /> : designs.map(d => 
                    <Link to={`/${route?.slug}/${d.id}/${d.link}`} key={`design-${d.id}`}>
                        <img src={d.url} alt="" />
                        <div className="overlay">{t['Chcem tento motív']}</div>
                    </Link>
                )}

            </div>

        </div>
    )
}


export default Motives;

