import React from "react"
import "./cart.scss"
import { Link } from "react-router-dom"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { useCartState } from "../../store/hooks/cart"
import cartIcon from "../../images/icons/cart.png"
import { META_ORDER } from "../../config"

const Cart = () => {
  const t = useTranslations([
    'Nákupný košík'
  ])
  const { cart } = useCartState();
  const { config } = useAppState();
  const route = config?.routes.meta.find(r => r.id === META_ORDER)

  if (!route)
    return null;

  return (
    <div id="cart">
      <Link to={`/${route.slug}`}><img src={cartIcon} alt={t['Nákupný košík']} />
        <span>{cart?.items.length || 0}</span>
      </Link>
    </div>
  )
}

export default Cart
