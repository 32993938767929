import { NavLink, Link, useLocation } from "react-router-dom"
import React, { useState } from "react"
import "./nav.scss"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { MEDIA_URL, DEFAULT_PRODUCT_CATEGORY, META_CONTACT, META_ACCOUNT } from "../../config"
import LanguageSelector from "./LanguageSelector"
import avatarIcon from "../../images/icons/avatar.png"
import Cart from "./Cart"

const Nav = () => {
  const { config } = useAppState()
  const loc = useLocation()

  const t = useTranslations([
    'Navrhni si tričko.sk', 
    'Návrhár',
    'Motívy',
    'Gravírovanie',
    'Kontakt',
    'O nás',
    'Moje objednávky',
    'vlastny-text',
    'fotka',
    'motivy'
  ])

  
  const [ hamburgerActive, showMobileMenu ] = useState(false);

  const toggleMobileMenu = (): void => {
    showMobileMenu(!hamburgerActive)
  }


  if (loc.pathname === "/docs3pd5cb821" || !config)
    return null;

  const hamburgerCls = hamburgerActive ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin";
  const navCls = hamburgerActive ? "active" : undefined;
  
  const creatorRoute = config?.routes?.products.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
  const contactRoute = config?.routes?.meta.find(r => r.id === META_CONTACT)
  const accountRoute = config?.routes?.meta.find(r => r.id === META_ACCOUNT)

  return (
    <header className="page">

      <button className={hamburgerCls} type="button" onClick={toggleMobileMenu}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>

      <nav className={navCls}>
        <NavLink exact activeClassName="active" to="/">
            <img src={`${MEDIA_URL}logo_${config.lang}.png`} alt={t['Navrhni si tričko.sk']} />
        </NavLink>

        <NavLink onMouseUp={showMobileMenu.bind(null, false)} exact activeClassName="active" to={`/${creatorRoute?.slug}/${t['vlastny-text']}/${t['fotka']}`}>{t['Návrhár']}</NavLink>
        <NavLink onMouseUp={showMobileMenu.bind(null, false)} exact activeClassName="active" to={`/${t['motivy']}`}>{t['Motívy']}</NavLink>
        {config.routes.categories.map(c => 
            <NavLink onMouseUp={showMobileMenu.bind(null, false)} key={`nav-${c.id}`} exact activeClassName="active" to={`/${c.slug}`}>{c.name}</NavLink>
        )}
        <NavLink onMouseUp={showMobileMenu.bind(null, false)} exact activeClassName="active" to={`/${contactRoute?.slug}`}>{t['Kontakt']}</NavLink>
      </nav>

      <div>
        <LanguageSelector />
        <Link to={`/${accountRoute?.slug}`}><img src={avatarIcon} alt={t['Moje objednávky']} /></Link>
        <Cart />
      </div>

    </header>
  )
}

export default Nav