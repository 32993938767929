import { get, post } from "./fetch"
import { CART_LOADED, CartActions, CartPayload } from "../types"

/* actions */

export async function changeItem(lang: string, id: number, amount: number): Promise<CartActions> {
    const cart = await post(`cart/${lang}`, {
        id, amount
    })

    return {
        type: CART_LOADED,
        items: cart.items,
        total: cart.total,
        notax: cart.notax,
        shipping: cart.shipping,
        voucher: cart.voucher,
        id: cart.id
    }
}

export async function loadCart(lang: string): Promise<CartActions> {
    const cart: CartPayload = await get(`cart/${lang}`)
    
    return {
        type: CART_LOADED,
        items: cart.items,
        total: cart.total,
        notax: cart.notax,
        id: cart.id,
        voucher: cart.voucher,
        shipping: cart.shipping,
    }
    
}
