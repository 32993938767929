import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslations, useAppState } from "../../store/hooks/app"
import "./form.scss"
import { post } from "../../store/actions/fetch"
import Button from "./Button"

interface RegisteredFormProps {
    onSubmit? (data: object): Promise<any>
}

const RegisteredUser = ({ onSubmit = async () => {} }: RegisteredFormProps) => {
    const { register, handleSubmit, errors } = useForm()
    const { config } = useAppState();
    
    const [ resetSuccess, setResetSuccess ] = useState<boolean>(false)
    const [ resetError, setResetError ] = useState<boolean>(false)
    const [ reseting, setReseting ] = useState<boolean>(false)
    const [ loginError, setLoginError ] = useState<boolean>(false)
    const [ processing, setProcessing ] = useState<boolean>(false)
    
    const t = useTranslations([
        'Prihlásiť sa',
        'E-mailová adresa',
        'Heslo',
        'Prihlásenie sa nepodarilo. Skontrolujte zadané údaje.',
        'Obnovenie sa nepodarilo. Skontrolujte zadané údaje.',
        'Obnoviť zabudnuté heslo',
        'Na Váš e-mail bol odoslaný aktivačný link, ktorým môžete znovu nastaviť svoje heslo.',
        'Spracovávam ...'
    ])

    if (!config)
        return null;

    return (
            <div>
                
                { (loginError || reseting) && <div className="errored">
                    {t['Prihlásenie sa nepodarilo. Skontrolujte zadané údaje.']}    
                    <div>
                        <button onClick={handleSubmit(async data => {
                            try {
                                setLoginError(false)
                                setReseting(true)
                                await post(`account/reset/${config.lang}`, data)
                                setResetSuccess(true)
                            }
                            catch (e) {
                                setResetError(true)        
                            }
                            setReseting(false)
                        })} className="anchor-like">{reseting ?  t['Spracovávam ...'] : t['Obnoviť zabudnuté heslo']}</button>
                    </div>
                </div>}

                { resetError && <div className="errored">
                    {t['Obnovenie sa nepodarilo. Skontrolujte zadané údaje.']} 
                </div>}  
                
                { resetSuccess && <div className="success">
                    {t['Na Váš e-mail bol odoslaný aktivačný link, ktorým môžete znovu nastaviť svoje heslo.']}    
                </div>}
                
                <div className="form">
                    <div>
                        <label htmlFor="registered-email">{t['E-mailová adresa']}</label>
                        <label htmlFor="registered-pasword">{t['Heslo']}</label>
                    </div>
                    <div>
                        <input className={errors && errors['registered-email'] && "errored"} type="text" name="registered-email" id="registered-email" ref={register({ required: true, minLength: 3})} />
                        <input className={errors && errors['registered-password'] && "errored"} type="password" name="registered-password" id="registered-password" ref={register({ minLength: 8})} />
                    </div>
                </div>

                <Button processing={processing} onClick={handleSubmit(async data => {
                    try {
                        setProcessing(true)
                        await onSubmit(data)
                    } catch (e) {
                        setLoginError(true)
                    }
                    setProcessing(false)
                })} title={t['Prihlásiť sa']} />
            </div>
    )
}

export default RegisteredUser