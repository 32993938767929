import React, { ReactNode } from "react"
import "./button.scss"

interface ButtonProps {
    title?: string
    disabled?: boolean
    onClick: () => Promise<any>
    children?: ReactNode
    processing?: boolean
}

export default ({ title, onClick, children, disabled = false, processing = false } : ButtonProps) => {

    return (
        <button disabled={processing || disabled} className={processing ? `processing` : undefined }  onClick={() => {
            (async () => {
                await onClick();
            })();
        }}>{title || children }</button>
    )
}

