import React, { useEffect, useState } from "react"
import "./step-three.scss"
import { useAppState } from "../../store/hooks/app"
import Buttons from "./Buttons"
import { useSelector, useDispatch } from "react-redux"
import { State, Carrier, ORDER_CARRIER } from "../../store/types"
import { get, post } from "../../store/actions/fetch"
import { useCartState } from "../../store/hooks/cart"
import { useForm } from "react-hook-form"
import Loader from "../Loader"

const StepThree = () => {
    const [ carriers, setCarriers ] = useState<Carrier[]>([])
    const { config } = useAppState();
    const { cart, goForward } = useCartState();
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm()
    const carrier = useSelector((state: State) => state.order.carrier)
    const [ packeteryCarriers, setPacketeryCarriers ] = useState<number[]>([]) 
    const [ processing, setProcessing ] = useState<boolean>(false)
    
    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const _carriers:Carrier[] = await get(`carriers/${config.lang}/${config.currency.code}/${config.country}`)
                const _packeteryCarriers = _carriers.filter(c => c.packetery).map(c => c.id)

                setCarriers(_carriers)
                setPacketeryCarriers(_packeteryCarriers)
                
                if (_packeteryCarriers.length && !document.getElementById('packetery-jsapi')) {
                    const el = document.createElement("script");
                    const head = document.getElementsByTagName('head')[0]
                    el.id = "packetery-jsapi"; el.async = true; el.src = "https://widget.packeta.com/www/js/library.js"
                    head.insertBefore(el, head.firstChild);
                }

            }
        })()
    }, [ config, cart ])

    if (!config?.lang || !carriers.length)
        return <Loader />

    return (
        <>  
        <div id="shipping">
            <div>
                {carriers.map(c => {
                    return <label key={`carrier-${c.id}`}>

                        <input type="radio" checked={carrier === c.id} value={c.id} ref={register({required: true})} name="carrier" onChange={() => {
                            dispatch({
                                type: ORDER_CARRIER,
                                carrier: c.id
                            })
                        }} /> 
                        {c.name} <span>{c.price}</span>
                        <p>
                            {c.image ? <img src={c.image} alt={c.name} /> : null}
                            {c.delay}
                        </p>
                    </label>
                })}
            </div>
            
            <div>
           
            </div>
            
        </div>
        <Buttons processing={processing} disabled={carriers.length === 0} handleForward={handleSubmit(async data => {
                setProcessing(true)        
                
                const saveCart = async (payload:any) => {
                    await post(`cart/${config.lang}`, payload)
                    goForward()
                }
                
                if (packeteryCarriers.includes(Number(data.carrier))) {

                    interface PacketeryPoint {
                        id: number
                        name: string
                        city: string
                        zip: string
                    }

                    //@ts-ignore
                    window.Packeta.Widget.pick("56dddfd954572dcb", (point: PacketeryPoint) => {
                        if (point) {
                            saveCart(Object.assign(data, { 
                                packetery: true,
                                id: point.id,
                                address: `${point.name}, ${point.zip}` }))
                        }
                        else
                            setProcessing(false)
                    }, {
                        language: config.lang.toLowerCase(),
                        country: config.country.toLowerCase()
                    })
                }
                else
                    await saveCart(data);

        })} />
        </>
    )
}


export default StepThree
