import React from 'react';
import './app.scss';
import Nav from "./Nav/Nav"
import Footer from "./Footer"
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Index from "./Index/Index"
import { useAppState, useTranslations } from "../store/hooks/app"
import Cms from './Cms/Cms';
import Category from './Category/Category';
import Creator from './Creator/Creator';
import NotFound from "./NotFound";
import Order from "./Order/Order"
import { META_ORDER, META_INDEX, META_CONTACT, META_ACCOUNT, DEFAULT_PRINTING_CATEGORY } from '../config';
import Confirmation from './Order/Confirmation';
import Contact from './Contact';
import Loader from './Loader';
import Account from './Account/Account';
import Docs from './Docs';
import Product from './Category/Product';
import Motives from './Category/Motives';

function App() {
  const { loaded, config } = useAppState()
  const t = useTranslations([
    'motivy'
  ])
  
  if (!loaded)
    return (
      <Loader />
    )

  const getComponent = (id: number) => {
    switch (id) {
      case META_INDEX: return <Index />
      case META_ORDER: return <Order />
      case META_ACCOUNT: return <Account />
      case META_CONTACT: return <Contact />
      
      default:
        return <NotFound />
    }
  }


  const metaRoutes = config?.routes?.meta.map(r => <Route exact key={`meta-route-${r.id}`} path={`/${r.slug}`}>{getComponent(r.id)}</Route>) || []
  const cmsRoutes = config?.routes?.cms.map(r => <Route key={`cms-route-${r.id}`} exact path={`/${r.slug}`}><Cms id={r.id} /></Route>) || []

  const categoryRoutes = config?.routes?.categories.map(r => <Route exact key={`category-route-${r.id}`} path={`/${r.slug}`}><Category id={r.id} /></Route>) || []
  const productRoutes = config?.routes?.products.map(r => <Route exact key={`product-route-${r.id}`} path={`/${r.slug}`}><Product id={r.idProduct} /></Route>) || []
  const creatorRoutes = config?.routes?.products.map(r => <Route key={`creator-route-${r.id}`} path={`/${r.slug}/:motive/:name?/:ipa?`}><Creator idCategory={r.id} idProduct={r.idProduct} /></Route>) || []
  const orderRoute = config?.routes?.meta.find(r => r.id === META_ORDER)
  const accountRoute = config?.routes?.meta.find(r => r.id === META_ACCOUNT)

  return (
    <BrowserRouter basename={config?.base}>
        
        <Nav />
        <Switch>

          <Route key="documentation" path="/docs3pd5cb821"><Docs /></Route>
          <Route key="reset-password-route" path={`/${accountRoute?.slug}/reset/:key/:token`}><Account resetPassword={true} /></Route>
          { cmsRoutes }
          { categoryRoutes }
          { productRoutes }
          { creatorRoutes }
          { metaRoutes }
          <Route key="customer-route" path={`/${accountRoute?.slug}/:key/:token`}><Account /></Route>
          <Route key="order-detail-route" path={`/${orderRoute?.slug}/:key`}><Confirmation /></Route>
          <Route key="motives" path={`/${t['motivy']}`}><Motives id={DEFAULT_PRINTING_CATEGORY} /></Route>
          <Route path='/*'><NotFound /></Route>    

        </Switch>
      
        <Footer/>
        
    </BrowserRouter>
  );
}

export default App;