import React from "react"
export default () => {
    
    return (
        <div className="product-controls-container">
            <div className="arrow arrow-up">
                <button className="anchor-like"><span className="fa fa-caret-up"></span></button>
            </div>
            <div className="block-container">
                <div className="block-scroll">
                    <div className="productView-views-container"></div>
                    <div className="combination-container"></div>
                </div>
            </div>
            <div className="arrow arrow-down">
                <button className="anchor-like"><span className="fa fa-caret-down"></span></button>
            </div>
        </div>
    )
}