import React from "react"
import "./promo.scss"
import promo1 from "../../images/about1.jpg"
import promo2 from "../../images/about2.jpg"
import { useTranslations } from "../../store/hooks/app"

const Promo = () => {
    const t = useTranslations([
        'Čo robíme?',
        'Robíme všetko pre to, aby sme Vám vyrábali a doručovali originálne a vtipné darčeky pre najrôznejšie príležitosti, alebo len tak pre radosť.',
        'S čím Vám pomôžeme?',
        'Čo ponúkame?',
        'Potlač textilu, rohoží a darčekových predmetov',
        'Vymýšľame motívy na tričká, detské body, hrnčeky, placky, vankúše a ďalšie darčeky, ktoré vás zaručene pobaví.',
    ]);

    return (
        <div id="promo">
            <img src={promo1} alt={t['Robíme všetko pre to, aby sme Vám vyrábali a doručovali originálne a vtipné darčeky pre najrôznejšie príležitosti, alebo len tak pre radosť.']} />
            <div>
                <h5>{t['Čo robíme?']}</h5>
                <h3>{t['S čím Vám pomôžeme?']}</h3>
                <p>{t['Robíme všetko pre to, aby sme Vám vyrábali a doručovali originálne a vtipné darčeky pre najrôznejšie príležitosti, alebo len tak pre radosť.']}</p>    
            </div>

            <div>
                <h5>{t['Čo ponúkame?']}</h5>
                <h3>{t['Potlač textilu, rohoží a darčekových predmetov']}</h3>
                <p>{t['Vymýšľame motívy na tričká, detské body, hrnčeky, placky, vankúše a ďalšie darčeky, ktoré vás zaručene pobaví.']}</p>    
            </div>
            <img src={promo2} alt={t['Robíme všetko pre to, aby sme Vám vyrábali a doručovali originálne a vtipné darčeky pre najrôznejšie príležitosti, alebo len tak pre radosť.']} />

        </div>
    )
}


export default Promo;

