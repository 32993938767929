import React, { useEffect, useState } from "react"
import "./step-four.scss"
import { useAppState, useTranslations } from "../../store/hooks/app"
import Buttons from "./Buttons"
import { Payment } from "../../store/types"
import { get, post } from "../../store/actions/fetch"
import { useCartState } from "../../store/hooks/cart"
import { useForm } from "react-hook-form"
import { META_ORDER, META_VOP } from "../../config"
import Loader from "../Loader"
import { Link } from "react-router-dom"

const StepFour = () => {
    const [ payments, setPayments ] = useState<Payment[]>([])
    const [ price, setPrice ] = useState<string>('')
    const { config } = useAppState()
    const { cart } = useCartState()
    const { handleSubmit, register } = useForm()
    const [ processing, setProcessing ] = useState<boolean>(false)
    const t = useTranslations([
        'Celková cena',
        'Dokončiť',
        'Stlačením tlačidla "Dokončiť" vyjadrujete súhlas s',
        'Všeobecnými obchodnými podmienkami',
        'Tu môžete pridať poznámku / dotaz k svojej objednávke'
    ])

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const _payments = await get(`payments/${config.lang}`)
                setPayments(_payments)
                setPrice(_payments[0].total || '')
                
            }
        })()
    }, [ config, cart ])

    if (!payments.length || !config?.lang)
        return <Loader />

    const route = config.routes.cms.find(r => r.id === META_VOP)
    return (
        <>  
        <div id="payments">
            <div>
                {payments.map(p => {
                    return <label key={`payment-${p.name}`} className={p.disabled ? `disabled`: undefined}>

                        <input type="radio" onClick={setPrice.bind(null, p.total)} value={p.name} ref={register({ required: true })} name="payment" disabled={p.disabled} /> 
                        {p.title} {p.price ? <span>{p.price}</span> : null}
                        <p>
                            <img src={p.image} alt={p.name} />
                            {p.desc}
                        </p>
                    </label>
                })}

                <div>
                    <label htmlFor="message">{t['Tu môžete pridať poznámku / dotaz k svojej objednávke']}:</label>
                    <textarea name="message" id="message" ref={register}></textarea>
                </div>
            </div>
            
        </div>
        
        <div id="totals">
            <div>{t['Celková cena']}: {price}</div>
            <div>
                {t['Stlačením tlačidla "Dokončiť" vyjadrujete súhlas s']}&nbsp;
                <Link to={`/${route?.slug}`} target="_blank">{t['Všeobecnými obchodnými podmienkami']}.</Link>
            </div>
        </div>

        <Buttons processing={processing} nextLabel={t['Dokončiť']} handleForward={handleSubmit(async data => {
            setProcessing(true)
            const redirect = await post(`cart/${config.lang}`, data)
            if (redirect && redirect.url)
                window.location.href = redirect.url
            else {
                const route = config?.routes.meta.find(r => r.id === META_ORDER)
                window.location.href =  `/${route?.slug}/${cart.key}`
            }
        })} />
        </>
    )
}


export default StepFour
