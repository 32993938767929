import React, { useState, ChangeEvent } from "react"
import "./step-one.scss"
import { useCartState } from "../../store/hooks/cart"
import { useTranslations, useAppState } from "../../store/hooks/app"
import Designs from "../Index/Designs"
import Buttons from "./Buttons"
import Button from "../Account/Button"
import { useDispatch } from "react-redux"
import { CART_FORWARD, CART_LOADED } from "../../store/types"
import { post } from "../../store/actions/fetch"

const StepOne = () => {
    const { cart, changeQuantity } = useCartState()
    const [ voucherError, setVoucherError ] = useState<boolean>(false)
    const [ voucherCode, setVoucherCode ] = useState<string>()
    const dispatch = useDispatch()
    const { config } = useAppState()

    const setDiscountCode = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value)
            setVoucherCode(e.target.value)
    }

    const t = useTranslations([
        'Nákupný košík je prázdny.',
        'bez DPH',
        'Mám zľavu',
        'Tento zľavový kód nie je správny.',
        'Doprava',
    ])

    if (!cart.items.length) {
        return <div className="empty-cart">
            <strong>{t['Nákupný košík je prázdny.']}</strong>
            <Designs />
        </div>
    }

    const items = cart.items.map(i => <div key={`item-${i.id}`} className="item">
        <div>
            <button onClick={changeQuantity.bind(null, i.id, 0)}><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#48d6d2" d="M 11.972656 9.003906 L 17.542969 3.433594 C 18.160156 2.816406 18.160156 1.820312 17.542969 1.207031 L 16.800781 0.460938 C 16.183594 -0.152344 15.1875 -0.152344 14.574219 0.460938 L 9.003906 6.03125 L 3.433594 0.460938 C 2.816406 -0.152344 1.820312 -0.152344 1.207031 0.460938 L 0.460938 1.203125 C -0.152344 1.820312 -0.152344 2.816406 0.460938 3.433594 L 6.03125 9.003906 L 0.460938 14.574219 C -0.152344 15.1875 -0.152344 16.1875 0.460938 16.800781 L 1.207031 17.542969 C 1.820312 18.160156 2.820312 18.160156 3.433594 17.542969 L 9.003906 11.972656 L 14.574219 17.542969 C 15.1875 18.160156 16.1875 18.160156 16.800781 17.542969 L 17.542969 16.800781 C 18.160156 16.183594 18.160156 15.1875 17.542969 14.574219 Z M 11.972656 9.003906 "/></svg></button>
        </div>

        <div className="images">
            {i.images.map((j, k) => <img key={`item-${i.id}-image-${k}`} src={j} alt={i.name} />)}
        </div>
        
        <div className="name">
            <div>{i.name}</div>
            <div>{i.combination}</div>
        </div>
        
        <div className="quantity">
            <button onClick={() => {
                if (i.quantity > 1)
                    changeQuantity(i.id, -1)
            }}><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#48d6d2"     d="M 16.78125 7.378906 L 1.226562 7.378906 C 0.550781 7.378906 0 7.929688 0 8.605469 L 0 9.425781 C 0 10.105469 0.550781 10.652344 1.226562 10.652344 L 16.78125 10.652344 C 17.457031 10.652344 18.007812 10.105469 18.007812 9.425781 L 18.007812 8.605469 C 18.007812 7.929688 17.457031 7.378906 16.78125 7.378906 Z M 16.78125 7.378906 "/></svg></button>
            {i.quantity}
            <button onClick={changeQuantity.bind(null, i.id, 1)}><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#48d6d2" d="M 16.761719 7.359375 L 10.628906 7.359375 L 10.628906 1.226562 C 10.628906 0.550781 10.082031 0 9.402344 0 L 8.585938 0 C 7.910156 0 7.359375 0.550781 7.359375 1.226562 L 7.359375 7.359375 L 1.226562 7.359375 C 0.550781 7.359375 0 7.910156 0 8.585938 L 0 9.402344 C 0 10.082031 0.550781 10.628906 1.226562 10.628906 L 7.359375 10.628906 L 7.359375 16.761719 C 7.359375 17.441406 7.910156 17.988281 8.585938 17.988281 L 9.402344 17.988281 C 10.082031 17.988281 10.628906 17.441406 10.628906 16.761719 L 10.628906 10.628906 L 16.761719 10.628906 C 17.441406 10.628906 17.988281 10.082031 17.988281 9.402344 L 17.988281 8.585938 C 17.988281 7.910156 17.441406 7.359375 16.761719 7.359375 Z M 16.761719 7.359375 "/></svg></button>
        </div>

        <div>
            {i.price}
        </div>

    </div>)

    const voucherDiv = cart.voucher && 
        <div className="item">
            <div>
                <button onClick={async () => {
                    const data = await post(`cart/${config?.lang}`, {
                        remove: cart.voucher?.name
                    })
                    if (data)
                        dispatch({ type: CART_LOADED, ...data })
                }}><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><path fill="#48d6d2" d="M 11.972656 9.003906 L 17.542969 3.433594 C 18.160156 2.816406 18.160156 1.820312 17.542969 1.207031 L 16.800781 0.460938 C 16.183594 -0.152344 15.1875 -0.152344 14.574219 0.460938 L 9.003906 6.03125 L 3.433594 0.460938 C 2.816406 -0.152344 1.820312 -0.152344 1.207031 0.460938 L 0.460938 1.203125 C -0.152344 1.820312 -0.152344 2.816406 0.460938 3.433594 L 6.03125 9.003906 L 0.460938 14.574219 C -0.152344 15.1875 -0.152344 16.1875 0.460938 16.800781 L 1.207031 17.542969 C 1.820312 18.160156 2.820312 18.160156 3.433594 17.542969 L 9.003906 11.972656 L 14.574219 17.542969 C 15.1875 18.160156 16.1875 18.160156 16.800781 17.542969 L 17.542969 16.800781 C 18.160156 16.183594 18.160156 15.1875 17.542969 14.574219 Z M 11.972656 9.003906 "/></svg></button>
            </div>

            <div className="name">{cart.voucher.name}</div>
            <div className="price">- {cart.voucher.discount}</div>
        </div>


    return (<>
        { voucherError && <div className="errored">
            {t['Tento zľavový kód nie je správny.']}
        </div>}
        <div>
            
            {items}
            {voucherDiv}
            <div className="totals">
                <div>{cart.shipping.length > 0 && <span>{t['Doprava']}: {cart.shipping}</span>}</div>
                <div>{cart.notax} {t['bez DPH']}</div>
                <div>{cart.total}</div>
                <div>
                    <input type="text" name="discount" onChange={setDiscountCode} /><Button onClick={async () => {
                        try {
                            const data = await post(`cart/${config?.lang}`, {
                                voucher: voucherCode
                            });
                            dispatch({
                                type: CART_LOADED,
                                ...data
                            })
                            
                        }
                        catch (e) {
                            setVoucherError(true)
                        }
                    }} title={t['Mám zľavu']} />
                </div>
            </div>

            <Buttons handleForward={async () => {
               dispatch({ type: CART_FORWARD })
            }} />
        </div>
        </>
    )
}


export default StepOne
