import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslations, useAppState } from "../../store/hooks/app"
import "./form.scss"
import Button from "./Button"

interface PasswordFormProps {
    onSubmit? (data: object): Promise<any>
}

const PasswordForm = ({ onSubmit = async () => {} }: PasswordFormProps) => {
    const { register, handleSubmit, errors } = useForm()
    const { config } = useAppState();
    
    const [ resetPassword, setResetPassword ] = useState<string>('')
    
    const t = useTranslations([
        'Heslo',
        'Potvrďte heslo',
        'Potvrdiť'
    ])

    if (!config)
        return null;

    return (
            <>
            <div className="form">
                    <div>
                        <label htmlFor="password">{t['Heslo']}</label>
                        <label htmlFor="confirmation">{t['Potvrďte heslo']}</label>
                    </div>
                    <div>
                        <input type="password" defaultValue={resetPassword} className={errors && errors['password'] && "errored"} name="password" id="password" ref={register({ required: true, minLength: 8 })} onChange={e => setResetPassword(e.target.value)} />
                        <input type="password" className={errors && errors['confirmation'] && "errored"} name="confirmation" id="confirmation" ref={register({ required: true, validate: val => val === resetPassword })} />
                    </div>
            </div>

            <Button onClick={handleSubmit(async data => {
                await onSubmit(data)
            })} title={t['Potvrdiť']} />
            </>
        
    )
}

export default PasswordForm