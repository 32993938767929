import React from "react"
import Controls from "./Controls"
import Canvas from "./Canvas"
import Custom from "./Custom"
import Products from "./Products"
import Printings from "./Printings"
import Text from "./Text"

interface Props {
    idCategory: number,
    lang: string
    baseUrl: string
    restUrl: string
}

export default ({ lang, idCategory, baseUrl, restUrl }: Props) => {
 
    return (
        <>
        <div id="creator-app">
            
            <div className="color-chooser-container" style={{display: "none"}}>
                <input type="text" value="000" name="colorPicker" id="colorPicker" className="color-picker-template" readOnly={true} />
            </div>
            
            <div className="designer-container">
                <div className="shirt-canvas-container-wrapper">
                    <div className="shirt-canvas-container">
                        {/*<div className="main-product-navigation">
                            <ul className="nav nav-tabs">
                                <li data-category="6">
                                    <div>
                                        <div><span className="glyph textiles-glyph"></span> <span className="desc iq-trans" data-code="textiles">Textiles</span></div>
                                        <div className="main-caret"></div>
                                    </div>
                                </li>

                                <li data-category="8">
                                    <div>
                                        <div><span className="glyph gifts-glyph"></span> <span className="desc iq-trans"
                                                                                        data-code="gifts">Gifts</span></div>
                                        <div className="main-caret"></div>
                                    </div>
                                </li>

                                <li data-category="9">
                                    <div>
                                        <div><span className="glyph stamps-glyph"></span> <span className="desc iq-trans"
                                                                                            data-code="stamps">Stamps</span>
                                        </div>
                                        <div className="main-caret"></div>

                                    </div>
                                </li>
                                
                                <li data-category="95">
                                    <div>
                                        <div><span className="glyph doormat-glyph"></span> <span className="desc iq-trans"
                                                                                            data-code="doormats">Doormats</span>
                                        </div>
                                        <div className="main-caret"></div>

                                    </div>
                                </li>

                            </ul>
    </div> */}

                        <div className="product-load-wrapper">
                            <div className="loading-element"></div>
                            <div className="table-container">
                                <Controls />
                                <Canvas baseUrl={baseUrl} />
                            </div>
                        </div>

                        <div className="object-controls dynamic">
                            <span id="rotateButton" className="btn btn-default control iq-trans" data-code="tooltip.rotate"
                                data-attr="title" data-toggle="tooltip" title="Rotate">
                                <span className="fa fa-rotate-right"></span>
                            </span>

                            <span id="resizeButton" className="btn btn-default control iq-trans"
                                data-code="tooltip.resize" data-attr="title" data-toggle="tooltip" title="Resize">
                                <span className="fa fa-expand"></span>
                            </span>

                            <span id="arcButton" className="btn btn-default control iq-trans" data-code="tooltip.arc"
                                data-attr="title" data-toggle="tooltip" title="Circle text">
                                <img className="iq-trans" data-code="tooltip.arc" data-attr="alt" src={`${baseUrl}creator/img/arc_up.png`}
                                    alt="Circle text"/>
                            </span>

                            <span id="removeButton" className="btn btn-default control iq-trans" data-code="tooltip.remove"
                                data-attr="title" data-toggle="tooltip" title="Remove">
                                <span className="fa fa-trash-o"></span>
                            </span>
                            <span id="qualityStars" className="quality-panel control">
                                <span className="indicator fa fa-star-o"></span>
                                <span className="indicator fa fa-star-o"></span>
                                <span className="indicator fa fa-star-o"></span>
                                <span className="indicator fa fa-star-o"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="object-container-wrapper">
                    <div className="object-container">
                        <ul className={`nav nav-tabs controls-tab ${lang}`}>
                            <li className="active"><a href="#products"><i className="fa fa-gift"></i> <span className="iq-trans"
                                                                                                    data-code="products">Products</span></a>
                            </li>
                            <li><a href="#printings"><i className="fa fa-picture-o"></i> <span className="iq-trans"
                                                                                        data-code="printings">Printings</span></a>
                            </li>
                            <li><a href="#customPrintings"><i className="fa fa-camera-retro"></i> <span className="iq-trans"
                                                                                                    data-code="customPrintings">Custom printings</span></a>
                            </li>
                            <li><a href="#text"><i className="fa fa-pencil"></i> <span className="iq-trans" data-code="text">Text</span></a>
                            </li>
                        </ul>

                        <div id="templates-toolbar">
                            <button className="anchor-like pull-right discard-template">
                                <i className="fa fa-times"></i>
                            </button>
                            <p>Aplikace běží v módu pro vytváření šablon.</p>

                            <input type="text" name="template-name" className="form-control form-inline" placeholder="Identifikátor šablony" maxLength={127} />
                            <select name="template-language-select" className="form-control"></select>
                            <button className="btn btn-default save-template">
                                <i className="fa fa-save"></i>
                                Uložit
                            </button>

                            <div className="template-message"></div>
                        </div>

                        <div className="tab-content">
                            <div className="tab-pane fade in active" id="products">
                                <Products />
                            </div>
                            <div className="tab-pane fade" id="printings">
                                <Printings idCategory={idCategory} />
                            </div>
                            <div className="tab-pane fade" id="customPrintings">
                                <Custom lang={lang} idCategory={idCategory} superSized={false} baseUrl={baseUrl} restUrl={restUrl} />
                            </div>
                            <div className="tab-pane fade" id="text">
                                <Text baseUrl={baseUrl} />
                            </div>
                        </div>

                        <div className="help-container collapsed">
                            <div className="help-container-content">
                                <button type="button" data-placement="left" className="close collapsible iq-trans"
                                        data-toggle="tooltip"
                                        data-code="tooltip.hideHelp" data-attr="title" title="Close help">
                                    <span aria-hidden="true">&times;</span></button>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 collapsible">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <div className="help-cell">
                                                        <div className="help-button">
                                                            <span className="fa fa-shield"></span>
                                                        </div>
                                                        <div className="help-text"><span className="iq-trans" data-code="help.flip">Flip horizontally</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="help-cell">
                                                        <div className="help-button">
                                                            <span className="layer-down"></span>
                                                        </div>
                                                        <div className="help-text"><span className="iq-trans"
                                                                                    data-code="help.layerDown">Move layer down</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <div className="help-cell">
                                                        <div className="help-button">
                                                            <span className="fa fa-tint"></span>
                                                        </div>
                                                        <div className="help-text"><span className="iq-trans" data-code="help.color">Change color</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="help-cell">
                                                        <div className="help-button">
                                                            <span className="layer-up"></span>
                                                        </div>
                                                        <div className="help-text"><span className="iq-trans" data-code="help.layerUp">Move layer up</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <div className="help-cell">
                                                        <div className="help-button">
                                                            <span className="fa fa-copy"></span>
                                                        </div>
                                                        <div className="help-text"><span className="iq-trans"
                                                                                    data-code="help.duplicate">Duplicate object</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="object-info">

                            <div className="col-xs-7">
                                <div className="price"><span className="value iq-trans"
                                                        data-toggle="tooltip" data-placement="top"
                                                        data-code="tooltip.price"
                                                        data-attr="title" title="Price">0</span>
                                    <span className="iq-trans" data-code="currency">&euro;</span>

                                    <div className="main-price">

                                        <div className="base-price-wrapper">
                                            (<span className="base-price">0</span> 
                                             <span className="iq-trans" data-code="currency">&euro;</span> / 
                                            <span className="iq-trans" data-code="items">ks</span>)
                                        </div>

                                        <span className="iq-trans" data-code="withTax"></span>
                                        <span className="iq-trans"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-code="tooltip.mainPrice"
                                            data-attr="title"><i className="fa fa-info-circle"></i>
                                        </span>
                                    </div>
                                        
                                    {/*<button className="anchor-like quantity-discounts"><i className="fa fa-percent"></i><span
                                            className="iq-trans" data-code="quantity.discounts">Quantity discounts</span></button> */}

                                    
                                    
                                </div>

                            </div>
                            <div className="col-xs-5">
                                <div className="control-group pull-right">
                                    <div className="btn btn-info btn-md control-create-order">
                                        <span className="fa fa-shopping-cart iq-load"></span>
                                        <span className="iq-trans" data-code="button.cart.insert">Insert into cart</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12">

                                <div className="hidden promotions-warning">
                                    <span className="iq-trans" data-code="promotions.warning"></span>
                                </div>

                                <div className="get-discount-info">
                                    <span className="iq-trans" data-code="getDiscount"></span> <span className="iq-trans red-font" data-code="getDiscountCode"></span>
                                </div>

                                <input id="comboTechnologies" type="hidden" data-toggle="dropdown" className="form-control iq-trans" data-attr="value" data-code="digital"
                                                value="digital" />

                            </div>
                        </div>



                    </div>
                </div>


                <div className="col-xs-12" id="previews-container">
                    <div className="tabs">
                        <button type="button" name="product-info" className="iq-trans active" data-code="tabs.productInfo">Product
                            Info
                        </button>
                        <button type="button" name="delivery" className="iq-trans" data-code="tabs.delivery">Payment and Delivery
                        </button>

                    </div>
                    <div className="row payment-delivery">Payment</div>
                    <div className="row previews-wrapper">
                        <div className="col-sm-7 col-xs-12">

                            <div className="previews"></div>
                            <div className="quantities"></div>
                        </div>
                        <div className="col-sm-5 col-xs-12">
                            <div className="description"></div>
                            <div className="table-of-sizes"></div>

                        </div>
                    </div>

                    
                </div>


            </div>


            <div id="help-overlay" className={`lang_${lang}`}></div>


            <div className="modal fade" tabIndex={-1} id="quantity-discounts-modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title iq-trans" data-code="quantity.discounts">Quantity discounts</h4>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 iq-trans quantity-desc" data-code="quantity.desc">Využite našu ponuku
                                množstevných zliav! Pozrite sa do tabuľky a ak si objednáte počet kusov aspoň v uvedenom
                                množstve, dostanete zľavu napísanú v pravej časti tabuľky.
                            </div>
                            <div className="col-xs-12 discounts-wrapper">
                                <div className="img-wrapper">
                                    <img className="background" src="#" alt="Navrhni si tričko"/>
                                    <img className="upper-canvas" src="#" alt="Navrhni si tričko"/>
                                </div>
                                <div className="discounts-table">

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary iq-trans" data-code="tooltip.closeAlert"
                                    data-dismiss="modal">Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" tabIndex={-1} id="cart-modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close dialog-negative-action" data-dismiss="modal"
                                    aria-label="Close"><span
                                    aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title iq-trans" data-code="button.cart.insert">Select size and quantity</h4>
                        </div>
                        <div className="modal-body">
                            <table className="combination-table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th className="iq-trans" data-code="cart.size">Size</th>
                                    <th className="iq-trans" data-code="cart.color">Color</th>
                                    <th className="iq-trans" data-code="cart.quantity">Quantity</th>
                                    <th className="iq-trans" data-code="cart.availability">Availability</th>
                                </tr>
                                </thead>
                                <tbody></tbody>
                            </table>

                            <div className="modal-bottom">
                                
                               {/* <div className="discounts-table">
                                    <h4 className="iq-trans" data-code="quantity.discounts">Quantity discounts</h4>

                                    <div className="cart-discounts"></div>
                                        </div> */}
                                
                                <div className="order-details">
                                <div className="col-xs-8 product-previews"></div>
                                    <div className="total-price col-xs-4"></div>
                                    
                                    <div className="modal-actions pull-right">
                                        <button type="button" className="btn btn-primary iq-trans dialog-negative-action"
                                                data-code="tooltip.closeAlert" data-dismiss="modal">Close
                                        </button>
                                        <button type="button" className="btn btn-primary dark iq-trans dialog-positive-action"
                                                data-code="tooltip.addToCart" data-dismiss="modal">Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="clearfix"></div>

                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </>
    )
}