import React, { useState } from "react"
import "./language.scss"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { MEDIA_URL } from "../../config"

const LanguageSelector = () => {
  const { config } = useAppState()
  const [ open, setOpen ] = useState<boolean>(false)
  const t = useTranslations([
    'Vyber jazyk', 
  ])

  if (!config)
    return null;


  const used: string[] = []
  const links = config.urls.map(u => {
    if (!used.includes(u.lang) && u.lang !== config.lang) {
        used.push(u.lang)
        return <a key={`lang-${u.lang}`} href={u.url}>
            <img src={`${MEDIA_URL}icons/${u.lang}.png`} alt={u.name} /> {u.name}
        </a>        
    }
    return null;
  })

  return (
    <div id="language-selector" onMouseLeave={setOpen.bind(null, false)}>

      <img src={`${MEDIA_URL}icons/${config.lang}.png`} alt={t['Vyber jazyk']} onMouseEnter={setOpen.bind(null, true)} onClick={setOpen.bind(null, !open)} />
      
      <div className={open ? 'active' : undefined}>
            {links}
      </div>

    </div>
  )
}

export default LanguageSelector
