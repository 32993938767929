import React from "react"


interface PrintingsProps {
    idCategory: number
}

export default ({ idCategory } : PrintingsProps) => {
    
    return (
        <div id="printing-flex-wrapper">
    <div className="filter iq-load-component">
        <div className="col-xs-7 printing-category-wrapper">
            <div className="input-group printing-category iq-trans" data-code="tooltip.printingCategory" data-attr="title"
                 data-toggle="tooltip">
                <span className="input-group-btn">
                    <input id="comboPrinting" type="button" data-toggle="dropdown" style={{ textAlign: "left"} }
                           className="form-control iq-trans" data-attr="value" data-code="combo.printings" value="Category"/>
                    <ul className="dropdown-menu"></ul>

                    <button type="button" className="btn btn-default" data-toggle="dropdown">
                        <span className="caret"></span>
                    </button>
                </span>
            </div>
        </div>
        <div className="col-xs-7 product-templates-select">

            <div className="input-group template-filters iq-trans" data-code="combo.filters" data-attr="title"
                 data-toggle="tooltip">
                 <span className="input-group-btn">
                        <input id="comboFilters" type="button" data-toggle="dropdown" style={{ textAlign: "left"} }
                               className="form-control iq-trans" data-attr="value" data-code="combo.filters" value="By employment"/>
                        <ul className="dropdown-menu filters-dropdown"></ul>
                        <button type="button" className="btn btn-default" data-toggle="dropdown">
                            <span className="caret"></span>
                        </button>
                </span>
            </div>
        </div>
    </div>

    <div className={`iq-trans stamp-label ${idCategory !== 9 && 'hidden'}`} data-code="template.heading">Kliknite na niektorú zo šablón a môžete si ju zeditovať:</div>

    <div className="product-templates-pool">

    </div>

    <div className={`iq-trans stamp-label ${idCategory !== 9 && 'hidden'}`} data-code="shapes.motivation">Alebo použite niektorý z tvarov:</div>
    <div className="printing-elements">
    </div>
</div>

    )
}