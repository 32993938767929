import React from "react"
import "./slider.scss"
import slider1 from "../../images/slider1.jpg"
import slider2 from "../../images/slider2.jpg"
import slider3 from "../../images/slider3.jpg"
import slider4 from "../../images/slider4.jpg"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { Link } from "react-router-dom"
import { DEFAULT_PRODUCT_CATEGORY, DEFAULT_TOPS_CATEGORY } from "../../config"


const Slider = () => {
    const t = useTranslations([
        'Navrhnúť vlastné tričko',
        'Kreatívne oblečenie pre malých školákov',
        'Jar prináša farby, ty originalitu',
        'Tričká pre mamičky s deťmi',
        'vlastny-text',
        'fotka',
        'motivy'
      ])
    const { config } = useAppState()

    if (!config)
        return null;

    const creatorRoute = config?.routes?.products.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
    const shirtsRoute = config?.routes?.categories.find(r => r.id === DEFAULT_PRODUCT_CATEGORY)
    const topsRoute = config?.routes?.categories.find(r => r.id === DEFAULT_TOPS_CATEGORY)

    return (
        <div id="slider">
            <div></div>
            <Link to={`/${creatorRoute?.slug}/${t['vlastny-text']}/${t['fotka']}`}>
                <img src={slider1} alt={t['Navrhnúť vlastné tričko']} />
                <button>{t['Navrhnúť vlastné tričko']}</button>
            </Link>
            
            <Link to={`/${t['motivy']}?id=60`}>
                <img src={slider2} alt={t['Kreatívne oblečenie pre malých školákov']} />
                <h2>{t['Kreatívne oblečenie pre malých školákov']}</h2>
            </Link>
            
            <div>
                <Link to={`/${topsRoute?.slug}`}>
                    <img src={slider4} alt={t['Jar prináša farby, ty originalitu']} />
                    <h2>{t['Jar prináša farby, ty originalitu']}</h2>
                </Link>
                <Link to={`/${shirtsRoute?.slug}`}>
                    <img src={slider3} alt={t['Tričká pre mamičky s deťmi']} />
                    <h2>{t['Tričká pre mamičky s deťmi']}</h2>
                </Link>
            </div>
        </div>
    )
}


export default Slider;

