import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslations, useAppState } from "../../store/hooks/app"
import { State, COUNTRIES_LOADED, ORDER_INVOICE, ORDER_DELIVERY } from "../../store/types"
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../store/actions/fetch"
import { MAIL_PATTERN } from "../../config"
import "./form.scss"

interface CustomerFormProps {
    showPassword?: boolean
    showOldPassword?: boolean
    disableEmail?: boolean
    showNewsletter?: boolean
    showDelivery?: boolean
    showGDPR?: boolean

}

const CustomerForm = ({
    showPassword = false,
    showOldPassword = false,
    disableEmail = false,
    showNewsletter = true,
    showDelivery = false,
    showGDPR = false
}: CustomerFormProps) => {

    const t = useTranslations([
        'Celé jméno',
        'E-mailová adresa',
        'Tel. číslo',
        'Fakturačná adresa',
        'Adresa doručenia',
        'Město',
        'PSČ',
        'Potvrďte heslo',
        'Heslo',
        'Vaše staré heslo',
        'Mám záujem o pravidelný newsletter',
        'Súhlasím s podmienkami užitia osobných údajov',
        'Chcem pridať inú doručovaciu adesu',
        'Chcem si vytvoriť stály účet'
    ])

    const { register, errors, watch, setValue, getValues } = useFormContext()
    const { config } = useAppState()

    const countries = useSelector((state:State) => state.app.countries)
    const customer = useSelector((state:State) => state.order.customer)

    const invoice = useSelector((state:State) => state.order.invoice)
    const invoiceIso = useSelector((state:State) => state.order.invoice.country)
    const delivery = useSelector((state:State) => state.order.delivery)
    const deliveryIso = useSelector((state:State) => state.order.delivery.country)

    const [ oldPassword, setOldPassword ] = useState<string>('')
    const [ accountPassword, setAccountPassword ] = useState<string>('')
    
    const dispatch = useDispatch()
    const deliveryOpen = watch("delivery")

    useEffect(() => {
        (async () => {
            if (config?.lang && !countries.length) {
                const _countries = await get(`countries/${config.lang}`)
                
                dispatch({
                    type: COUNTRIES_LOADED,
                    countries: _countries
                })

                if (deliveryIso === '') {
                    dispatch({
                        type: ORDER_DELIVERY,
                        data: { country: config.country }
                    })
                }
                setValue('country_2', deliveryIso || config.country)

                if (invoiceIso === '') {
                    dispatch({
                        type: ORDER_INVOICE,
                        data: { country: config.country }
                    })
                } 
                setValue('country', invoiceIso || config.country)
            }

        })()

    }, [ config, dispatch, invoiceIso, deliveryIso, countries, setValue ])
    

    useEffect(() => {
        if (delivery.street !== '')
            setValue('delivery', true)
    }, [ delivery, setValue ])

    if (!config)
        return null;

    return (
        <>
            <div className="form">
                <div>
                    <label htmlFor="name">{t['Celé jméno']}</label>
                    <label htmlFor="email">{t['E-mailová adresa']}</label>
                    <label htmlFor="phone">{t['Tel. číslo']}</label>

                    {showOldPassword && 
                        <label htmlFor="old-password">{t['Vaše staré heslo']}</label>
                    }
                    {showPassword && <>
                        <label htmlFor="password">{t['Heslo']}</label>
                        <label htmlFor="confirmation">{t['Potvrďte heslo']}</label>
                    </>}
                    <label htmlFor="street">{t['Fakturačná adresa']}</label>
                    <label htmlFor="city">{t['Město']}</label>
                    <label htmlFor="zip">{t['PSČ']}</label>
                </div>

                <div>
                    <input type="text" className={errors && errors['name'] && "errored"} name="name" id="name" defaultValue={customer.name} ref={register({ required: true, pattern: /[A-Za-zÀ-ÖØ-öø-ÿ.]+\s+[A-Za-zÀ-ÖØ-öø-ÿ.]/ })} />
                    <input type="text" disabled={disableEmail} className={errors && errors['email'] && "errored"} name="email" id="email" defaultValue={customer.email} ref={register({ required: true,  pattern: MAIL_PATTERN })} />
                    <input type="text" className={errors && errors['phone'] && "errored"} name="phone" id="phone" defaultValue={customer.phone} ref={register({ required: true,  minLength: 6 })} />

                    {showOldPassword && <>
                        <input type="password" defaultValue={oldPassword} className={errors && errors['old-password'] && "errored"} name="old-password" id="old-password" ref={register({ required: false, minLength: 8 })} onChange={e => setOldPassword(e.target.value)} />
                    </>}
                    
                    {showPassword && <>    
                        <input type="password" defaultValue={accountPassword} className={errors && errors['password'] && "errored"} name="password" id="password" ref={register({ required: !showOldPassword, minLength: 8 })} onChange={e => setAccountPassword(e.target.value)} />
                        <input type="password" className={errors && errors['confirmation'] && "errored"} name="confirmation" id="confirmation" ref={register({ required: !showOldPassword, validate: val => val === accountPassword })} />
                    </>}

                    <input type="text" className={errors && errors['street'] && "errored"} name="street" id="street" defaultValue={invoice.street} ref={register({ required: true, pattern: /[A-Za-zÀ-ÖØ-öø-ÿ]+\s+[0-9]+/ })} />
                    <input type="text" className={errors && errors['city'] && "errored"} name="city" id="city" defaultValue={invoice.city} ref={register({ required: true })} />
                    <input type="text" className={errors && errors['zip'] && "errored"} name="zip" id="zip" defaultValue={invoice.zip} ref={register({ required: true, validate: val => {
                        const { country } = getValues()
                        const invoiceCountry = countries.find(c => c.iso === country)
                        return val.replace(/\s/g, '').match(new RegExp(`^${invoiceCountry?.zip || '*'}$`)) !== null 
                    }
                    })} />

                    <select name="country" defaultValue={invoiceIso} ref={register({ required: true })}>
                        {countries && countries.map(c => <option value={c.iso} key={`country-${c.iso}`}>{c.name}</option> )}
                    </select>
                </div>
            </div>

            <div className="form-options">

            {showNewsletter &&
                <div className="one-line">
                    <input type="checkbox" defaultChecked={customer.newsletter} name="newsletter" id="newsletter" ref={register} />
                    <label className={errors && errors['newsletter'] && "errored"} htmlFor="newsletter">{t['Mám záujem o pravidelný newsletter']}</label>
                </div>
            }

            { showDelivery && 
                <div>
                    <input type="checkbox" defaultChecked={delivery.street !== ''} name="delivery" id="delivery" ref={register} />
                    <label htmlFor="delivery">{t['Chcem pridať inú doručovaciu adesu']}</label>

                    { deliveryOpen && <div className="form">
                        <div>
                            <label htmlFor="street_2">{t['Adresa doručenia']}</label>
                            <label htmlFor="city_2">{t['Město']}</label>
                            <label htmlFor="zip_2">{t['PSČ']}</label>
                        </div>
                        <div>
                            <input type="text" defaultValue={delivery.street} className={errors && errors['street_2'] && "errored"} name="street_2" id="street_2" ref={register({ pattern: /[a-zA-Z]+\s+[0-9]+/, required: true })} />
                            <input type="text" defaultValue={delivery.city} className={errors && errors['city_2'] && "errored"} name="city_2" id="city_2" ref={register({ required: true })} />
                            <input type="text" defaultValue={delivery.zip} className={errors && errors['zip_2'] && "errored"} name="zip_2" id="zip_2" ref={register({ required: true, validate: val => {
                                const { country_2 } = getValues()
                                const deliveryCountry = countries.find(c => c.iso === country_2)
                                return val.replace(/\s/g, '').match(new RegExp(`^${deliveryCountry?.zip || '*'}$`)) !== null 
                            }})} />
                            
                            <select name="country_2" defaultValue={deliveryIso} ref={register}>
                                {countries && countries.map(c => <option value={c.iso} key={`country-${c.iso}`}>{c.name}</option> )}
                            </select>
                        </div>
                    </div>
                    }

                </div>
            }

            { showGDPR && 
                <div>
                    <input type="checkbox" name="gdpr" id="gdpr" ref={register({ required: true })} />
                    <label className={errors && errors.gdpr && "errored"} htmlFor="gdpr">{t['Súhlasím s podmienkami užitia osobných údajov']}</label>
                </div>
            }
        </div>
        </>
    )
}

export default CustomerForm