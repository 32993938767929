import React, { useEffect, useState, ChangeEvent } from "react"
import "./category.scss"
import { CategoriesPayload, Attribute, ProductList } from "../../store/types";
import { useAppState, useTranslations } from "../../store/hooks/app";
import { get } from "../../store/actions/fetch"
import { Link } from "react-router-dom";
import Loader from "../Loader";
import DrawIcon from "../../images/icons/drawing.png"
import { MEDIA_URL } from "../../config";

interface CategoryProps {
    id: number
}

const Products = ({ id }: CategoryProps) => {
    const PER_PAGE = 12;
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ products, setProducts ] = useState<ProductList[]>([])
    const [ colors, setColors ] = useState<Attribute[]>()
    const [ sizes, setSizes ] = useState<Attribute[]>()
    const [ sort, setSort ] = useState<string>('BY_DATE')
    const [ page, setPage ] = useState<number>(1)
    
    const [ hamburgerActive, showMobileMenu ] = useState(false);

    const { config } = useAppState();
    const t = useTranslations([
        'od najnovších',
        'podľa ceny',
        'Pridať motív',
        'všetky veľkosti'
    ])

    const toggleColor = (id: number) => {
        setPage(1)
        setColors(colors?.map(c => Object.assign(c, { active: (c.active && c.id !== id) || (!c.active && c.id === id) })))
    }

    const toggleSize = (e: ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSizes(sizes?.map(s => Object.assign(s, { active: s.id === Number(e.target.value)})))
    }

    const toggleMobileMenu = (): void => {
        showMobileMenu(!hamburgerActive)
    }

    useEffect(() => {
        (async () => {
            if (config?.lang) {
                const payload: CategoriesPayload = await get(`products/${id}/${config.lang}`)
                if (payload) {
                    setColors(payload.attributes.colors)
                    setSizes(payload.attributes.sizes)
                    setProducts(payload.products)
                    setLoading(false)
                }
            }
        })()
    }, [ config, id ])


    if (loading)
        return <Loader />

    if (!products)
        return null;

    
    const hamburgerCls = hamburgerActive ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin";
    const navCls = hamburgerActive ? "active" : undefined;

    let usedColors: number[] = [];

    const selectedColors = colors?.filter(c => c.active).map(c => c.id) || []
    const selectedSize = sizes?.find(s => s.active)

    const filtered = products
        .filter(p => 
            !selectedColors.length || p.attributes.find(
                combination => combination.a.find(a => selectedColors.includes(a))
            )
        )
        .filter(p => 
            !selectedSize || p.attributes.find(
                combination => combination.a.find(a => a === selectedSize.id)
        ))

    if (sort === 'BY_PRICE')
        filtered.sort((a, b) => a.price_raw < b.price_raw ? -1 : a.price_raw === b.price_raw ? 0 : 1)

    const offset = (page - 1) * PER_PAGE;
    
    const productDivs = filtered.slice(offset, offset + PER_PAGE)
        .map(p => {
            const available = p.images.filter(i => !selectedColors.length || selectedColors.includes(i.id))
            let color = available.find(i => !usedColors.includes(i.id))
            if (!color) {
                color = available[0]
                usedColors.splice(0, 10)
            }
            usedColors.push(color.id)

            return <Link key={`product-${p.id}`} to={`${p.slug}?c=${color.id}`} className="product">
                <img src={`${MEDIA_URL}p/${color.url}`} alt={p.name} />
                <span className="price">{p.price}</span>
                <span className="name">{p.name}</span>
                <span className="overlay">
                    <img src={DrawIcon} alt={t['Pridať motív']} />
                {t['Pridať motív']}
                </span>
            </Link>
        })

    let pagination = [];
    for (let i = 1; i <= Math.ceil(filtered.length / PER_PAGE); i++)
        pagination.push(<button disabled={i === page} className={i === page ? `active` : undefined} key={`page-${i}`} onClick={setPage.bind(null, i)}>{i}</button>)

    return (
        <div id="category">
            <nav className={navCls}>

                <button className={hamburgerCls} type="button" onClick={toggleMobileMenu}>
                    <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                    </span>
                </button>
                <div id="filters">
                    <div className="colors">
                        {colors?.map(c => {
                            const style = c.color ? {backgroundColor: c.color} : { backgroundImage: `url(${MEDIA_URL}co/${c.id}.jpg)` }
                            return <span onClick={toggleColor.bind(null, c.id)} className={c.active ? `active` : undefined} key={`color-${c.id}`} style={style} title={c.name}></span>    
                        })}
                    </div>

                    <div className="sizes">
                        <select onChange={toggleSize} defaultValue={sizes?.find(s => s.active)?.id}>
                            <option  key={`size-all`} value={undefined}>{t['všetky veľkosti']}</option>    
                        {sizes?.map(c => 
                            <option  key={`size-${c.id}`} value={c.id}>{c.name}</option>    
                        )}
                        </select>
                    </div>

                    <div className="sort">
                        <select onChange={e => setSort(e.target.value)}>
                            <option value="BY_DATE">{t['od najnovších']}</option>
                            <option value="BY_PRICE">{t['podľa ceny']}</option>
                        </select>
                    </div>
                </div>

            </nav>

            <div>
                {loading ? <Loader /> : productDivs}
            </div>

            {pagination.length > 1 && <div className="pagination">    
                {pagination}
            </div>}

        </div>
    )
}


export default Products;

