import React from "react"

interface CustomProps {
    idCategory: number
    lang: string
    superSized: boolean
    baseUrl: string
    restUrl: string
}

export default ({ idCategory, lang, superSized, baseUrl, restUrl }: CustomProps) => {
    /*const matsAddition = ['hu', 'en'].includes(lang) ? null :
        <>
            <hr />
            <p>
                <span className="iq-trans" data-code="doormats.warning.p3">Ak potrebuješ využiť pomoc našich grafikov (odstrániť pozadie, či iné menšie úpravy – max. 30 min. práce), v košíku pri objednávke zaklikni kolónku „Chcem odstrániť pozadie alebo vykonať iné grafické úpravy“. Do políčka „Poznámky“ nám čo najpodrobnejšie opíš, o aké grafické úpravy máš záujem. Za grafické úpravy sa účtuje cena</span>
                <span className="iq-trans bold" data-code="doormats.warning.price">8 EUR na 30 min.</span>
            </p>
            <p>
                <span className="iq-trans" data-code="doormats.warning.footer">Grafické úpravy vieme vykonať iba v prípade, ak je Tvoja objednávka zaplatená a máš zvolenú možnosť „Chcem odstrániť pozadie alebo vykonať iné grafické úpravy“.</span>
            </p>
        </>*/
            
    
    return (
        <>
        <div className="iq-load-component">
    <div className="custom-file-container">
        <div className="row custom-upload-error">
            <div className="col-sm-12">
                    <i className="fa fa-warning"></i>
                    <span className="iq-trans" data-code="upload.error">
                        File upload failed. Please try again.
                    </span>
            </div>
        </div>
        <form className="form-horizontal custom-file-form">
            <div>
                <div>
                    <span className="btn btn-default btn-sm custom-fileinput-button">
                        <i className="fa fa-plus"></i>
                        {idCategory === 9 ? (
                            <span className="iq-trans select-file" data-code='file.selectLogo'>Select file</span> ||
                            <span className="iq-trans select-file" data-code='file.select'>Select file</span>
                        ) : null}
                            
                        
                        <input id="customFile" name="customFile" data-url={`${restUrl}file/image`}
                               type="file"/>
                    </span>
                    <span className="custom-file-help"><span className="iq-trans"
                                                         data-code="upload.custom">Upload custom image</span></span>
                </div>
            </div>
        </form>
        
        <div className="custom-file-info">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                            <span className="iq-trans" data-code="upload.info">
                                <p>When upload finished, click on the file and it appears on product.</p>
                            </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="custom-file-metadata">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <span className="filename"></span>
                    </div>
                    <div className="col-sm-6">
                        <div className="pull-right">
                            <button className="custom-file-submit btn btn-info btn-sm"><i
                                    className="fa fa-arrow-circle-o-up iq-load"></i> <span className="iq-trans"
                                                                                       data-code="upload">Upload</span>
                            </button>
                            <button className="custom-file-cancel btn btn-default btn-sm"><i className="fa fa-ban"></i> <span
                                    className="iq-trans" data-code="cancel">Cancel</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="custom-printing-container">
        </div>
    </div>

    {/*<div className="quality-wrapper {if $id_parent_category == 9 || $id_parent_category == 95}hidden{/if}">
        <div className="col-sm-12 quality-warning">
            <div>
                <i className="fa fa-warning"></i>
                <span className="iq-trans" data-code="quality.warning.s1">Pre kvalitnú potlač odporúčame nahrať obrázky v rozlíšení od 1500 px. Váš obrázok má</span>
                <span className="width">XXX</span>x<span className="height">YYY</span>
                <span className="iq-trans" data-code="quality.warning.s2"> px. V prípade potreby nahraďte obrázok.</span>
            </div>
                        </div>*/}

        <div className="col-sm-12 quality-help">
            <h5><span className="iq-trans" data-code="quality.heading">Quality</span>:</h5>

            <div><i className="fa fa-star"></i><i className="fa fa-star-o"></i><i className="fa fa-star-o"></i><i
                    className="fa fa-star-o"></i> - <span className="iq-trans" data-code="quality.low">low</span></div>
            <div><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i><i
                    className="fa fa-star-o"></i> - <span className="iq-trans" data-code="quality.average">average</span></div>
            <div><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i
                    className="fa fa-star-o"></i> - <span className="iq-trans" data-code="quality.good">good</span></div>
            <div><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i
                    className="fa fa-star"></i> - <span className="iq-trans" data-code="quality.high">high</span></div>
        </div>

        {/*
        <p className="col-sm-12 quality-recommendation iq-trans" data-code="quality.recommendation">
            Pre kvalitnú potlač odporúčame nahrať obrázky v rozlíšení od 1500 px. Nahraním vlastnej grafiky potvrdzujete vaše duševné vlastníctvo obrázku.
        </p>
    </div>

    <div className="col-xs-12 doormats-warning {if $id_parent_category != 95}hidden{/if}">
        <a href={`${baseUrl}creator/img/doormats_sample.jpg`} className="thickbox">
            <img className={`pull-right mats-16 ${superSized ? 'hidden' : ''}`} src={`${baseUrl}creator/img/doormats_sample.jpg`} alt="navrhnisitricko.sk" />
            <img className={`pull-right mats-22 ${!superSized ? 'hidden': ''}`} src={`${baseUrl}creator/img/doormats_sample22.jpg`} alt="navrhnisitricko.sk" />
        </a>

        <h5 className="iq-trans bold red" data-code="doormats.warning.heading">Upozornenie:</h5>
        <p>
            <span className="iq-trans bold" data-code="doormats.warning.p1">Na potlač rohožiek sa používa konkrétna paleta</span>
            <span className="bold colors">{superSized ? '22' : '16'}</span>
            <span className="iq-trans bold" data-code="doormats.warning.p2">farieb. Nie je možné zabezpečiť 100% zhodu farieb zobrazených na obrazovke počítača v porovnaní s dodanou rohožou, malé rozdiely v odtieni sú prípustné.</span>
        </p>

        <p className="bold red iq-trans" data-code="doormats.warning.colors">
            Uvedená cena platí pre rohože s maximálne 3-farebnou grafikou. Za každú ďalšiu farbu sa účtuje +8 €! Pri tlači vlastných obrázkov/grafík je nutná konzultácia s grafikmi a úprava obrázkov do farieb z dostupnej palety. Vlastné plnofarebné fotografie nie je možné tlačiť.
        </p>
        {matsAddition}
    
    </div>

    <div className={`col-xs-12 graphic-supervision ${(idCategory === 9 || (idCategory === 95 && lang !== "en" && lang !== "hu")) ? 'hidden' : ''}`}>
        <h5>
            <span className="iq-trans" data-code="graphic.heading1">Potrebujete upraviť&nbsp; Vašu grafiku?</span>
            <span className="iq-trans" data-code="graphic.heading2">Chcete odstrániť&nbsp; pozadie z Vášho obrázka?</span>
        </h5>

        <p>
            <span className="iq-trans" data-code="graphic.p1">Náš grafik Vám rád odstráni pozadie či vykoná podobné menšie grafické úpravy za jednotnú cenu</span>
            <span className="iq-trans bold" data-code="graphic.price">8 EUR/obrázok</span>.
            <span className="iq-trans" data-code="graphic.p2">V košíku pri objednávke zakliknite kolónku</span>
            <span className="iq-trans underline" data-code="graphic.checkbox">„Chcem odstrániť pozadie alebo vykonať iné grafické úpravy“</span>
            <span className="iq-trans" data-code="graphic.p3">a do políčka</span>
            <span className="iq-trans underline" data-code="graphic.message">"Poznámky"</span>
            <span className="iq-trans" data-code="graphic.p4">nám čo najpodrobnejšie opíšte o aké grafické úpravy máte záujem. (Jedná sa o úpravy menšieho rozsahu, max. 30 min. práce grafika)</span>.
        </p>

        <p>
            <span className="iq-trans" data-code="graphic.p5">Grafické úpravy vieme vykonať, iba v prípade ak je Vaša objednávka zaplatená a máte zvolenú možnosť</span>
            <span className="iq-trans underline" data-code="graphic.checkbox">“Chcem odstrániť pozadie alebo vykonať iné grafické úpravy“</span>.
        </p>
                        </div>*/}

    


    <div className={`col-xs-12 stamp-docs ${idCategory !== 9 ? 'hidden' : ''}`}>
        <hr />
        <div className="custom-doc-container">
            <div className="iq-trans" data-code="documents">Have you finished your design? Here you can upload
                additional documents (doc, txt, docx, pdf).
            </div>
            <form className="form-horizontal custom-doc-form">
                <div>
                    <div>
                    <span className="btn btn-default btn-sm custom-fileinput-button">
                        <i className="fa fa-plus"></i> <span className="iq-trans" data-code="file.select">Select file</span>
                        <input id="customDoc" name="customDoc" data-url={`${restUrl}file/doc`} type="file"/>
                        <input id="idCart" name="idCart" value="{$id_cart}" type="hidden"/>
                    </span>
                    <span className="custom-file-help"><span className="iq-trans"
                                                         data-code="upload.doc">Upload custom document</span></span>
                    </div>
                </div>
            </form>
            <div className="custom-file-metadata">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="progress">
                                <div className="progress-bar" role="progressbar"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <span className="filename"></span>
                        </div>
                        <div className="col-sm-6">
                            <div className="pull-right">
                                <button className="custom-file-submit btn btn-info btn-sm"><i
                                        className="fa fa-arrow-circle-o-up iq-load"></i> <span className="iq-trans"
                                                                                           data-code="upload.doc">Upload</span>
                                </button>
                                <button className="custom-file-cancel btn btn-default btn-sm"><i
                                        className="fa fa-ban"></i> <span
                                        className="iq-trans" data-code="cancel">Cancel</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom-documents-wrapper"></div>
        </div>
    </div>
    <div className="clearfix"></div>
</div>
</>


    )
}