import { 
  AppState, AppActions, CONFIG_LOADED, COUNTRIES_LOADED, CUSTOMER_LOGIN, CUSTOMER_LOGOUT
} from "../types"

export function appReducer (state: AppState = {
  loaded: false,
  config: null, 
  customer: false,
  countries: []
}, action: AppActions): AppState {
    
    switch (action.type) {
      case CONFIG_LOADED:
        return Object.assign({}, state, { config: action.config, customer: action.config.customer, loaded: true })

      case CUSTOMER_LOGIN:
        return Object.assign({}, state, { customer: true })

      case CUSTOMER_LOGOUT:
        return Object.assign({}, state, { customer: false })

      case COUNTRIES_LOADED:
        return Object.assign({}, state, { countries: action.countries })

      default:
        return state
    }
}
