import React from 'react'
import { useTranslations } from '../store/hooks/app'
import Designs from './Index/Designs'
import "./not-found.scss"

function NotFound() {
    const t = useTranslations([
        'Ospravedlňujeme sa, táto stránka nebola nájdená.'
    ])

  return (

    <div id="not-found">
      <strong>{t['Ospravedlňujeme sa, táto stránka nebola nájdená.']}</strong>
      <Designs />
    </div>
  );
}

export default NotFound;