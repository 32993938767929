export const API_URL : string = process.env.NODE_ENV === 'production' ? 'https://navrhnisitricko.sk/rest/v3/' : 'http://127.0.0.1/rest/v3/'
export const MEDIA_URL: string = process.env.NODE_ENV === 'production' ? 'https://navrhnisitricko.sk/img/' : 'http://127.0.0.1/img/'
export const STORAGE_KEY : string = '9g9P56O125L1te9V'
export const DEFAULT_PRODUCT: number = 91;
export const ROOT_PRODUCT_CATEGORY = 2;
export const ROOT_PRINTING_CATEGORY = 21;
export const DEFAULT_PRODUCT_CATEGORY = 259;
export const DEFAULT_TOPS_CATEGORY = 261;
export const DEFAULT_PRINTING_CATEGORY = 263;
export const DEFAULT_LADIES_CATEGORY = 260;
export const DEFAULT_KIDS_CATEGORY = 261;

// eslint-disable-next-line
export const MAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const META_INDEX = 4;
export const META_ORDER = 24;
export const META_ABOUT = 13;
export const META_ACCOUNT = 18;
export const META_CONTACT = 3;
export const META_VOP = 11;

export class Err extends Error {
    public code = 0

    constructor(code: number, message: string) {
      super(message)
      this.code = code
    }
}