export interface AppState {
    config: ConfigPayload | null
    loaded: boolean
    countries: Country[]
    customer: boolean
}

interface Address {
    street: string | undefined
    city: string | undefined
    zip: string | undefined
    country: string | undefined
}

interface Customer {
    name: string | undefined
    email: string | undefined
    phone: string | undefined
    newsletter: boolean
}

interface Dynamic {
    [key:string]: string | boolean
}

export interface OrderAddress {
    invoice: Dynamic
    customer: Dynamic
    delivery: Dynamic
}

export interface OrderState {
    carrier: number | undefined
    payment: string | undefined
    invoice: Address
    customer: Customer
    delivery: Address
}


export interface CartState {
    loaded: boolean | undefined
    id: number | undefined
    key: string | undefined
    step: number
    total: string
    notax: string
    shipping: string
    voucher: Voucher | null
    items: CartItem[]
}

export interface State {
    app: AppState
    cart: CartState
    order: OrderState
}

/* request payloads */
interface Route {
    id: number
    idProduct?: number
    name?: string
    slug: string
}

interface Routes {
    cms: Route[]
    categories: Route[]
    products: Route[]
    meta: Route[]
}

interface Currency {
    code: string
    sign: string
    decimals: number
}

interface Url {
    lang: string
    name: string
    url: string
}

export interface Country {
    iso: string
    zip: string
    name: string
}

export interface ConfigPayload {
    urls: Url[]
    url: string
    base: string
    lang: string
    country: string
    currency: Currency
    routes: Routes
    token: string
    customer: boolean
    cart: CartPayload
    translations: {[t: string]: string}
} 

export interface Category {
    id: number
    name: string
}

export interface Attribute {
    id: number
    name: string
    color?: string
    active?: boolean
}

export interface Attributes {
    colors: Attribute[]
    sizes: Attribute[]
}

export interface ProductDetail {
    id: number
    name: string
    desc: string
    slug: string
    ipa: number
    ipas: number[]
    price: string
    table: string
    images: string[]
    previews: string[]
}

interface ProductListImage {
    id: number
    url: string
}

interface ProductAttribute {
    ipa: number
    a: number[]
}

export interface ProductList {
    id: number
    name: string
    price: string
    price_raw: number
    slug: string
    attributes: ProductAttribute[]
    images: ProductListImage[]
}

export interface CategoriesPayload {
    attributes: Attributes
    products: ProductList[]
}

export interface Insta {
    id: string
    thumb: string
    label: string
    link: string
}

export interface InstaPayload {
    instagram: Insta[]
}

export interface TranslationsPayload {
    translations: {[t: string]: string}
}

export interface CreatorPayload extends TranslationsPayload {
    id_product: number,
    id_parent: number
}

export interface Design {
    id: number
    url: string
}

export interface Order {
    reference: string
    payment: string
    carrier: string
    showBankwire: boolean
    total: string
    state: string
    paid: boolean
    error: boolean
}

export interface CategoryDesign extends Design {
    name: string
    link: string
}

export interface DesignPayload {
    id: number
    url: string
    name: string
    link: string
}

export interface CartItem {
    id: number
    id_product: number
    quantity: number
    price: string
    name: string
    combination: string
    images: string[]
}

interface Voucher {
    name: string
    discount: string
    free_shipping: boolean
}

export interface CartPayload {
    id: number
    total: string
    notax: string
    shipping: string
    voucher: Voucher | null
    items: CartItem[]
}

export interface Carrier {
    id: number
    name: string
    international: boolean
    cod: boolean
    price: string
    image: string | false
    delay: string
    packetery: boolean
}

export interface Payment {
    name: string
    image: string
    title: string
    desc: string
    price?: string
    total: string
    disabled: boolean
}

export type GetPayload = ConfigPayload | TranslationsPayload | CreatorPayload | DesignPayload[] | CartPayload

export interface Order {
    reference: string
    date: string
    amount: string
    delivery: string
    address: string
    paid: boolean
    error: boolean
    state: string
}

export interface Account {
    name: string
    email: string
    phone: string
    street: string
    city: string
    country: string
}

export interface AccountPayload extends Account {
    orders: Order[]
}

/* action types */

export const CONFIG_LOADED = 'CONFIG_LOADED'
export interface ConfigLoadedAction {
    type: typeof CONFIG_LOADED
    config: ConfigPayload
}

export const CART_LOADING = 'CART_LOADING'
interface CartLoadingAction extends CartPayload {
    type: typeof CART_LOADING
}


export const CART_LOADED = 'CART_LOADED'
interface CartLoadedAction extends CartPayload {
    type: typeof CART_LOADED
}

export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN'
interface CustomerLogInAction {
    type: typeof CUSTOMER_LOGIN
}


export const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT'
interface CustomerLogOutAction {
    type: typeof CUSTOMER_LOGOUT
}


export const CART_FORWARD = 'CART_FORWARD'
export const CART_BACKWARDS = 'CART_BACKWARDS'
interface CartForwardAction {
    type: typeof CART_FORWARD
}
interface CartBackwardsAction {
    type: typeof CART_BACKWARDS
}

export const COUNTRIES_LOADED = 'COUNTRIES_LOADED'
export interface CountriesLoadedAction {
    type: typeof COUNTRIES_LOADED
    countries: Country[]
}

export const ORDER_CUSTOMER = 'ORDER_CUSTOMER'
export const ORDER_INVOICE = 'ORDER_INVOICE'
export const ORDER_DELIVERY = 'ORDER_DELIVERY'

export interface OrderFieldAction { 
    type: typeof ORDER_CUSTOMER | typeof ORDER_INVOICE | typeof ORDER_DELIVERY
    data: object
}

export const ORDER_CARRIER = 'ORDER_CARRIER'
export interface OrderCarrierAction {
    type: typeof ORDER_CARRIER
    carrier: number
}

export type AppActions = ConfigLoadedAction | CountriesLoadedAction | CustomerLogInAction | CustomerLogOutAction
export type CartActions = CartLoadedAction | CartForwardAction | CartBackwardsAction | CartLoadingAction | ConfigLoadedAction
export type OrderActions = OrderFieldAction | OrderCarrierAction | CustomerLogOutAction