import { get } from "./fetch"
import { CONFIG_LOADED, ConfigLoadedAction, ConfigPayload } from "../types"
import { STORAGE_KEY } from "../../config"

/* actions */

export async function loadConfig(): Promise<ConfigLoadedAction> {
    const _parts = window.location.pathname.split('/')
    const iso = _parts.length > 1 &&_parts[1].length ? _parts[1] : 'default'
    
    const config:ConfigPayload = await get(`config/${iso}`)
    
    if (config?.token)
        localStorage.setItem(STORAGE_KEY, config.token)

    return {
        type: CONFIG_LOADED,
        config
    }
}
